quick-tip-button {
  &.hide-on-mobile {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.quick-tip-button {
  display: inline-block;
  font-size: $font-size-sm;
  margin: 0.5rem 0;

  .tooltip-icon {
    margin-right: 3px;

    &:before {
      font-size: 15px;
    }
  }

  .md-toolbar-tools & {
    color: $link-color;
  }

  &:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

.quick-tip-inbox .quick-tip-button {
  display: block;
  margin: -24px 3px 0px 0;

  @include media-breakpoint-up(sm) {
    margin: -10px 3px 4px 0;
  }

  @include media-breakpoint-up(md) {
    margin: -30px 3px 9px 0;
  }
}

.quick-tip-above-search .quick-tip-button {
  display: block;

  @include media-breakpoint-up(sm) {
    margin: -15px 3px 4px 0;
  }

  @include media-breakpoint-up(md) {
    margin: -30px 3px 9px 0;
  }
}

.nav-bar-with-help .quick-tip-button {
  display: block;
  position: absolute;
  right: 30px;
  top: 12px;
}
