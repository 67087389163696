.alert-default {
  @include alert-variant($gray-lightest, #ccc, $text-color);
}

.alert {
  border: 0;
  position: relative;

  a:not(.btn) {
    text-decoration: underline;
  }

  .action {
    margin-top: $line-height-computed/2;
  }

  .close {
    float: none;
    opacity: 0.3;
    position: absolute;
    right: 10px;
    top: 6px;

    &:hover {
      opacity: 0.5;
    }
  }

  .icon {
    display: none;
  }

  //&.alert-danger {
  //    a:not(.btn) {
  //        color: $alert-danger-text;
  //    }
  //}
  //
  //&.alert-info {
  //    a:not(.btn) {
  //        color: $alert-info-text;
  //    }
  //}
  //
  //&.alert-success {
  //    a:not(.btn) {
  //        color: $alert-success-text;
  //    }
  //}
  //
  //&.alert-warning {
  //    a:not(.btn) {
  //        color: $alert-warning-text;
  //    }
  //
  //    .icon {
  //        color: $alert-warning-border;
  //    }
  //}

  p {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: $line-height-computed/2;
  }

  .message {
    padding: 0;
    width: 100%;
  }

  h3,
  h4,
  .h3,
  .h4 {
    font-family: $font-family-sans-serif;
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    display: table;
    width: 100%;

    .icon {
      display: table-cell;
      margin: -1em 0;
      padding-right: $grid-gutter-width/2;
      vertical-align: middle;
      width: 2em;

      //.alert-warning & {
      //    color: $alert-warning-border;
      //}
    }

    .message {
      display: table-cell;
      padding: 0;
      vertical-align: middle;
      width: 100%;
    }

    .action {
      display: table-cell;
      margin: 0;
      padding: 0 $grid-gutter-width/2;
      vertical-align: middle;
    }
  }
}
