.account-swatch {
  background: $brand-alt;
  border-radius: $badge-border-radius;
  display: inline-block;
  height: 10px;
  margin-right: 6px;
  width: 10px;

  &.account-swatch-lg {
    height: 50px;
    width: 50px;
  }

  .modal-title & {
    height: 12px;
    margin-bottom: 2px;
    width: 12px;
  }
}

.account-color-picker {
  //@include clearfix();
}

.account-color {
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  float: left;
  margin-right: $line-height-computed/2;
  padding: 10px;
  text-align: center;

  &.active,
  &:hover {
    background-color: $table-hover-bg;
  }

  &.active {
    border-color: $gray-lighter;
  }

  input {
    display: inline-block;
    margin: 8px 4px 0;
  }

  .account-swatch {
    border-radius: 4px;
    display: block;
    margin: 0;
  }
}

.account-search {
  margin-top: $line-height-computed;
  max-width: 100%;
  width: 100%;

  @media (min-width: 600px) {
    margin-top: 0;
    width: 300px;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 0;
    width: 400px;
  }
}

.account-toolbar {
  padding: $line-height-computed/2 $grid-gutter-width/2 $line-height-computed/2;

  @include media-breakpoint-down(xs) {
    margin: 0 $grid-gutter-width/-2;
  }

  md-menu {
    .md-button {
      margin: 0;
    }
  }

  md-select {
    margin: 0 $grid-gutter-width/2;
  }

  .grid-selected-count {
    margin-left: -19px;
    padding-bottom: 1px;
  }
}

.account-toggle-all {
  margin-right: 35px;
}

.grid-selected-count {
  color: $text-muted;
  font-weight: $font-weight-light;
  margin-right: 10px;
}
