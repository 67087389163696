.uib-datepicker,
.uib-timepicker {
  .btn-secondary {
    background: #fff;
    border: 0;
    color: $text-color;

    &:hover {
      background: #e6e6e6;
    }

    &.btn-info.active {
      background: $link-color;
      color: #fff;

      .text-info {
        color: #fff !important;
      }
    }
  }
}
