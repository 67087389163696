$width-adjustment: 280px;

.elevate-banner {
    width:100%;
    position: relative;
    background-color:#384f55;
    background-image:url(images/content/bg_elevate-banner3.jpg);
    background-size:cover;
    background-position: 50% 50%;
    padding: $grid-gutter-width/2;
    font-family: 'Lato', sans-serif;
    color:#ffffff;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    margin: $line-height-computed*-1 0 $line-height-computed;
    position: relative;

    strong {
        font-weight:400;
    }

    .elevate-close {
        position:absolute;
        top:0;
        right:0;
        a {
            font-family: 'Lato', sans-serif;
            color:#ffffff;
            display: block;
            text-decoration:none;
            font-size:12px;
            padding: 4px 10px;
        }
    }

    h2.subhead {
        font-size:23px;
    }

    h3.date {
        font-size: 19px;
    }

    h1.headline, h3.date, h2.subhead {
        font-weight:300;
        letter-spacing:.5px;
        margin:0 30px 0 0;
    }

    .elevate-logo {
        display:flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        img {
            height:auto;
            margin-right:25px;
            width:180px;
        }
    }

    h1.headline {
        font-size:30px;
        line-height:1em;
    }

    a.elevate-btn {
        text-decoration:none;
        display:flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        border:2px solid #ffffff;
        font-family: 'Lato', sans-serif;
        font-weight:700;
        text-transform: uppercase;
        padding:4px 12px;
        color:#ffffff;
        font-size:22px;
        letter-spacing: 2px;

        i.fa {
            font-size:17px;
            line-height:10px;
            padding-left:7px;
            padding-top:2.5px;
        }
    }

    @media(max-width: $screen-md){
        margin-top: 0;
    }

    @media (max-width: 767px) {
        & {
            display:none;
        }
    }

    @media (max-width: 992px + $width-adjustment) {
        h3.date {
            display:none;
        }
    }

    @media (max-width: 1360px + $width-adjustment) {
        h1.headline {
            display:none;
        }

        .elevate-logo img {
            width:170px;
            height:auto;
        }

        h2.subhead {
            font-size:21px;
        }

        a.elevate-btn {
            font-size:16px;
        }
    }

    @media (max-width: 1550px + $width-adjustment) {
        h1.headline {
            font-size:24px;
            line-height:1em;
        }

        h2.subhead {
            font-size:21px;
        }

        a.elevate-btn {
            font-size:16px;
        }
    }
}