.notifications {
  margin: $line-height-computed * -0.75 0 $line-height-computed * 1.5;
}

.notification-row {
  outline: none;

  &.unread {
    i {
      background-color: $brand-alt;
    }
  }

  img.emoji {
    height: 1.4em;
    margin: -0.2em 0;
    width: auto;
  }
}

.notification-cell-icon {
  width: 20px;

  i {
    @include transition(background 400ms ease-in-out);
    background-color: $gray-light;
    border-radius: 50%;
    color: #fff;
    height: 34px;
    text-align: center;
    width: 34px;
    padding: 10px;

    &:before {
    }
  }
}

.notification-summary {
  h4,
  .h4 {
    font-family: $font-family-sans-serif;
    margin: 0 0 $line-height-computed/4;
  }

  p {
    color: $text-muted;
    font-weight: 300;
    margin: 0;
  }
}

.notification-content {
  padding-top: $line-height-computed / 1.5;
}
