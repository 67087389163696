md-checkbox {

    td & {
        margin-bottom: 0;
    }

    .md-label {
        margin-left: 26px;
        vertical-align: top;
    }

    .form-group {
        margin: 0;
    }

    .form-group div[class^="col-"] & {
        margin-bottom: 7px;
        margin-top: 7px;
    }

}