.ui-grid-pager-panel {
  background: $headerBackgroundColor;
  border-radius: 0 0 $border-radius $border-radius;
  border-top: 1px solid $panel-inner-border;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px 0;
  box-sizing: content-box;
}

.ui-grid-pager-container {
  float: left;
}

.ui-grid-pager-control {
  margin-right: 15px;
  margin-left: 15px;
  min-width: 135px;
  float: left;

  button {
    height: 25px;
    min-width: 26px;
    @extend .btn;
    @include button-variant(
      $paginationButtonColor,
      $paginationButtonBackgroundColor,
      $paginationButtonBorderColor
    );
  }

  input {
    @extend .form-control;
    @extend .form-control-sm;
    display: inline;
    height: 26px;
    width: 50px;
    vertical-align: top;
  }

  .ui-grid-pager-max-pages-number {
    vertical-align: bottom;
    > * {
      vertical-align: middle;
    }
  }

  .first-bar {
    width: 10px;
    border-left: 2px solid $gray;
    margin-top: -6px;
    height: 12px;
    margin-left: -3px;
  }

  .first-bar-rtl {
    width: 10px;
    border-left: 2px solid $gray;
    margin-top: -6px;
    height: 12px;
    margin-right: -7px;
  }

  .ui-grid-icon-left-dir,
  .first-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8.7px 5px 0;
    border-color: transparent $gray transparent transparent;
    margin-left: 2px;
  }

  .ui-grid-icon-left-dir {
    margin-left: -2px;
  }

  .next-triangle {
    margin-left: 1px;
  }

  .prev-triangle {
    margin-left: 0;
  }
  .ui-grid-icon-right-dir,
  .last-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 8.7px;
    border-color: transparent transparent transparent $gray;
    margin-left: -1px;
  }

  .ui-grid-icon-right-dir {
    margin-left: 0;
    margin-right: -2px;
  }

  .last-bar {
    width: 10px;
    border-left: 2px solid $gray;
    margin-top: -6px;
    height: 12px;
    margin-left: 1px;
  }

  .last-bar-rtl {
    width: 10px;
    border-left: 2px solid $gray;
    margin-top: -6px;
    height: 12px;
    margin-right: -11px;
  }
}

.ui-grid-pager-row-count-picker {
  float: left;

  select {
    @extend .form-control;
    @extend .form-control-sm;
    height: 26px;
    width: 67px;
    display: inline;
  }

  .ui-grid-pager-row-count-label {
    margin-top: 3px;
  }
}

.ui-grid-pager-count-container {
  float: right;
  margin-top: 4px;
  min-width: 50px;

  .ui-grid-pager-count {
    margin-right: 15px;
    margin-left: 15px;
    float: right;
  }
}
