#wrapper {
  bottom: 0;
  overflow: hidden;
  position: absolute;
  top: $navbar-height;
  width: 100%;

  &.hasImpersonateBanner {
    top: $navbar-height * 2;
  }

  @include media-breakpoint-up(sm) {
    &.hasImpersonateBanner {
      top: $navbar-height * 2;
    }
  }
}

.scroller {
  overflow: auto;
}

#main {
  padding: 0;

  @include media-breakpoint-up(sm) {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.page {
  position: relative;

  .md-nav-bar {
    margin-top: $grid-gutter-width/3 * -1;
  }

  @include media-breakpoint-up(sm) {
    overflow: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }

  // flex
  .layout-column & {
    @include media-breakpoint-up(sm) {
      position: relative;
      bottom: auto;
      right: auto;
      top: auto;
      left: auto;
    }
  }
}

.page-header-bottom {
  padding: 40px 30px 0px !important;
}

.page-header {
  border: 0;
  margin: 0;
  padding: $line-height-computed $grid-gutter-width/2 $grid-gutter-width/3;

  @include media-breakpoint-up(md) {
    padding: $line-height-computed * 2 $grid-gutter-width $grid-gutter-width/2;
  }

  h1,
  .h1 {
    margin: 0;
  }

  .search-form {
    margin-bottom: $line-height-computed/2;
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 220px;
    }
  }

  .md-nav-bar {
    margin: $grid-gutter-width/3 0 $grid-gutter-width/3 * -1;

    @include media-breakpoint-up(sm) {
      margin: $grid-gutter-width/2 0 $grid-gutter-width/2 * -1;
    }
  }

  .inbox-nav-bar {
    .md-nav-bar {
      margin-left: $grid-gutter-width/-2;
      margin-right: $grid-gutter-width/-2;

      @include media-breakpoint-up(md) {
        margin-left: $grid-gutter-width * -1;
        margin-right: $grid-gutter-width * -1;
      }
    }
  }

  &.selected-conversation {
    display: none;
    @media (min-width: $screen-md) {
      display: block;
    }
  }
}

.page-header-status {
  margin-top: $line-height-computed/2;

  .status-icon {
    font-size: 20px;
    bottom: -2px;
    position: relative;
    margin-right: 2px;
  }
}

.high-speed-inbox {
  .page-title {
    span {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  .credit-balance {
    margin: $line-height-computed/2 $grid-gutter-width/2;
    @include media-breakpoint-up(md) {
      margin: 0 $grid-gutter-width;
    }
  }
}

.page-title[layout="row"] {
  .btn:not(.btn-link) {
    margin-left: $grid-gutter-width;
  }
}

.border-left {
  border-left: 1px solid $gray-lighter;
}

.page-toolbar {
  margin-top: $line-height-computed;

  .page-content & {
    margin: 0 0 $line-height-computed;
  }

  h1,
  .h1 {
    margin-bottom: $line-height-computed;

    @include media-breakpoint-up(xs) {
      float: left;
      margin-bottom: 0;
    }
  }
}

.action-group {
  @extend .clearfix;
  margin: $line-height-computed 0 $line-height-computed/2;
}

.page-toolbar,
.action-group {
  .search-form {
    margin-top: $line-height-computed/2;
    width: 100%;

    @include media-breakpoint-up(xs) {
      float: right;
      margin-top: 0;
      width: 35%;
    }
  }
}

.inbox-actions {
  @include clearfix;

  .page-header & {
    margin-top: $line-height-computed;
  }

  .action-group {
    float: left;
    margin: 0;

    .btn {
      margin: 0;

      & + .btn {
        margin-left: 4px;
      }
    }
  }
}

.page-help-link {
  font-size: $font-size-sm;
  float: right;
  margin-bottom: $line-height-computed/3;
  margin-top: $line-height-computed/3 * -1;
  text-align: right;

  a:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }

  .tooltip-icon:before {
    font-size: $font-size-base;
  }
}

.subpage-toolbar {
  background-color: #fafafa;
  margin: $line-height-computed * -1 $grid-gutter-width/2 * -1 0;
  min-height: 60px;
  padding: $line-height-computed/2 $grid-gutter-width/2;

  @include media-breakpoint-up(md) {
    margin: $line-height-computed * 2 * -1 $grid-gutter-width * -1 $grid-gutter-width/2 * -1;
  }

  &.has-page-header {
    margin-bottom: $line-height-computed * 1.5;
  }
}

.subpage-toolbar-table {
  display: table;
  margin: 2px 0;
  width: 100%;
}

.subpage-toolbar-back,
.subpage-toolbar-title,
.subpage-toolbar-actions {
  display: table-cell;
  vertical-align: middle;
}

.subpage-toolbar-actions {
  .md-button.md-icon-button {
    height: 34px;
    padding: 2px 8px;
  }

  .md-button {
    margin: 0 8px;
  }
}

.subpage-toolbar-back {
  padding-right: $grid-gutter-width/4;
  width: 43px;

  .btn {
    @include transition(background 100ms ease-in);
    border: none;

    i {
      color: $text-muted;
    }
  }

  .md-button {
    margin: 0;
    min-width: 0;

    i {
      margin: 0 4px;
      position: relative;
      top: 1px;
    }

    .link {
      color: $link-color;
      bottom: -1px;
      position: relative;
    }
  }
}

.subpage-toolbar-title {
  h1,
  .h1 {
    font-size: 20px;

    small {
      //font-size: 70%;
    }
  }
}

.subpage-toolbar-actions {
  text-align: right;

  .md-icon-button {
    //margin: -17px 0;

    i {
      color: lighten($text-muted, 10%);
      font-size: 24px;
    }
  }
}

.page-content,
.subpage-nav-bar {
  padding: $line-height-computed $grid-gutter-width/2 $grid-gutter-width/3;

  @include media-breakpoint-up(md) {
    padding: $line-height-computed $grid-gutter-width $grid-gutter-width/2;
  }
}

.page-content {
  md-content:not([md-scroll-y]) & {
    height: 100%;
  }
  &.scheduled-messages {
    overflow: auto;
    margin-top: $line-height-computed;
    padding: 0 $grid-gutter-width/2;

    @include media-breakpoint-up(sm) {
      margin-top: $line-height-computed;
      padding: 0 $grid-gutter-width;
    }
  }
}

.subpage-nav-bar {
  padding-bottom: 0;
}

.page-footer-nav {
  background: #fff;
  box-shadow: $footer-nav-box-shadow;
  padding: $line-height-computed/2 $grid-gutter-width;
  z-index: 1;
}

.help-feature {
  margin: $line-height-computed * 2 $grid-gutter-width;
  text-align: center;
}

.help-content {
  background: #fafafa;
  margin: $line-height-computed 0;
  padding: $line-height-computed $grid-gutter-width/2 $line-height-computed/2;

  h2,
  .h2 {
    margin-top: 0;
  }
}

#hs-beacon {
  display: none;

  iframe {
    left: 0;
    margin-bottom: 5px;
    right: auto;

    @include media-breakpoint-up(sm) {
      left: -14px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      left: 76px;
    }
  }

  &.right iframe {
    @include media-breakpoint-up(sm) {
      left: auto;
      margin-right: -14px;
    }

    @include media-breakpoint-up(md) {
      left: auto;
      margin-right: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    .auth & {
      display: block;
    }
  }
}

// Impersonate / Caretaker
#impersonate-banner {
  background-color: $blue;
  color: #fff;
  height: $navbar-height;
  padding: 15px 30px;

  a {
    color: #fff;
    position: absolute;
  }

  p {
    margin: 0 0 0 -30px;
    text-align: right;
  }

  &.isCaretaking {
    background-color: #fff596;
    color: $text-color;

    a {
      color: $text-color;
      font-weight: bold;
    }
  }

  @include media-breakpoint-up(sm) {
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 50;

    p {
      text-align: center;
    }
  }
}

#caretaker-dropdown {
  width: 100%;

  .caretaker-icon {
    bottom: -7px;
    color: #fff;
    display: none;
    margin-left: -45px;
  }

  .dropdown {
    &.open {
      .dropdown-toggle {
        //background: $navbar-inverse-link-active-bg;
        //border-color: transparent;
      }
    }
  }

  .dropdown-toggle {
    border: none;
    color: #fff;
    padding: 0.5rem 0;
    text-align: left;
    text-transform: capitalize;
    width: 100%;

    &:focus,
    &:hover {
      background: transparent;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    border: 0;
    margin-top: 0;
    min-width: 100%;
  }

  @include media-breakpoint-up(sm) {
    left: 50%;
    position: fixed;
    top: $dropdown-padding-y + 1;

    .dropdown {
      display: inline-block;
      transform: translate(-50%, 0);
    }

    .dropdown-toggle {
      border: 1px solid #fff;
      padding: 0.25rem 0.5rem;
      text-align: center;
      text-transform: uppercase;
      width: auto;
    }

    .caretaker-icon {
      display: inline-block;
    }
  }
}

.caretaker-icon {
  bottom: -3px;
  color: $brand-alt;
  display: inline-block;
  font-size: 24px;
  margin: -7px 8px 0 4px;
  position: relative;

  td & {
    float: left;
    margin: 6px 0 0 4px;
  }
}

td .hasIcon {
  margin-left: 48px;
}

.disclaimer-box {
  display: flex;
  color: #004085;
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  border-radius: 4px;
  margin: $line-height-computed * -1 0 $line-height-computed;
  padding: 1rem;
  .info-icon {
    flex-direction: column;
    align-self: baseline;
    padding-right: 1rem;

    .fa-info-circle {
      font-size: 20px;
    }
  }
  p {
    margin-bottom: 0.5rem;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: $blue;
    text-decoration: underline;
  }

  &.disclaimer-box-warning {
    color: #856404;
    background-color: #ffeeba;
    border: 1px solid #ffeeba;

    .info-icon {
      .fa-warning {
        font-size: 20px;
      }
    }
  }
}

.ui-grid-cell-contents {
  &.word-wrap {
    white-space: unset;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
