@import "ui-grid-sass/src/variables";

$gridBorderWidth: 0;
$borderColor: $panel-inner-border;

$headerBackgroundColor: $table-head-bg;
$headerGradientStart: $headerBackgroundColor;
$headerGradientStop: $headerBackgroundColor;

$rowColorEven: $table-accent-bg;
$rowColorOdd: #fff;

$paginationButtonBorderColor: $input-border;

@import "./elements";
@import "ui-grid-sass/src/grid";
@import "ui-grid-sass/src/header";
@import "ui-grid-sass/src/body";
@import "ui-grid-sass/src/cell";
@import "ui-grid-sass/src/footer";
@import "ui-grid-sass/src/menu";
@import "ui-grid-sass/src/sorting";
//@import "ui-grid-sass/src/icons";
//@import "ui-grid-sass/src/rtl";
//@import "ui-grid-sass/src/animation";

// Now some specifics to various features
//@import "ui-grid-sass/src/cellNav";
//@import "ui-grid-sass/src/edit";
//@import "ui-grid-sass/src/emptyBaseLayer";
//@import "ui-grid-sass/src/expandable";
//@import "ui-grid-sass/src/exporter";
//@import "ui-grid-sass/src/grouping";
//@import "ui-grid-sass/src/importer";
//@import "ui-grid-sass/src/colMovable";
@import "./pagination";
//@import "ui-grid-sass/src/pinning";
//@import "ui-grid-sass/src/column-resizer";
//@import "ui-grid-sass/src/rowEdit";
//@import "ui-grid-sass/src/selection";
//@import "ui-grid-sass/src/tree-base";
//@import "ui-grid-sass/src/tree-view";
//@import "ui-grid-sass/src/validate";

// icon extend start
[class^="ui-grid-icon"]:before,
[class*=" ui-grid-icon"]:before {
  @extend .fa;
  color: $gray;
}

.ui-grid-icon-blank::before {
  width: 1em;
  content: " ";
}

// *** TODO: replace these two
.ui-grid-icon-plus-squared:before {
  @extend .fa-plus-square-o;
}

.ui-grid-icon-minus-squared:before {
  @extend .fa-minus-square-o;
}
// *** END here

.ui-grid-icon-search:before {
  @extend .fa-search;
}

.ui-grid-icon-cancel:before {
  @extend .fa-times;
}

.ui-grid-icon-info-circled:before {
  @extend .fa-info-circle;
}

.ui-grid-icon-lock:before {
  @extend .fa-lock;
}

.ui-grid-icon-lock-open:before {
  @extend .fa-unlock;
}

.ui-grid-icon-pencil:before {
  @extend .fa-pencil;
}

.ui-grid-icon-down-dir:before {
  @extend .fa-sort-desc;
  position: relative;
  margin-left: 4px;
  bottom: 3px;
}

.ui-grid-icon-up-dir:before {
  @extend .fa-sort-asc;
  position: relative;
  margin-left: 4px;
  top: 2px;
}

// .ui-grid-icon-left-dir:before {
//   @extend .fa-caret-left;
// }

// .ui-grid-icon-right-dir:before {
//   @extend .fa-caret-right;
// }

.ui-grid-icon-left-open:before {
  @extend .fa-chevron-left;
}

.ui-grid-icon-right-open:before {
  @extend .fa-chevron-right;
}

.ui-grid-icon-angle-down:before {
  @extend .fa-angle-down;
}

.ui-grid-icon-filter:before {
  @extend .fa-filter;
}

.ui-grid-icon-sort-alt-up:before {
  @extend .fa-sort-amount-asc;
}

.ui-grid-icon-sort-alt-down:before {
  @extend .fa-sort-amount-desc;
}

.ui-grid-icon-ok:before {
  @extend .fa-check;
}

.ui-grid-icon-menu:before {
  @extend .fa-bars;
}

.ui-grid-icon-indent-left:before {
  @extend .fa-dedent;
}

.ui-grid-icon-indent-right:before {
  @extend .fa-indent;
}

.ui-grid-icon-spin5:before {
  @extend .fa-spinner;
  @extend .fa-spin;
}

// icon extend end

.ui-grid {
  height: 100% !important;
  position: relative;

  &.card {
    border-radius: $border-radius;
  }

  &.loading {
    .ui-grid-header {
      border-color: #fff;
    }

    .ui-grid-pager-container {
      display: none;
    }
  }

  .empty-message {
    font-size: $font-size-lg;
    font-style: italic;
    margin-top: $line-height-computed;
    position: absolute;
    text-align: center;
    top: 40%;
    width: 100%;
  }
}

.ui-grid-contents-wrapper {
  height: auto;
}

.ui-grid-pager-control {
  button {
    background: #fff;
    border-color: $gray-300;

    &:hover {
      background: darken($light, 5);
    }

    &:disabled {
      background: transparentize($light, 0.6);
      border-color: $gray-300;
    }
  }
}

.ui-grid-top-panel {
  font-weight: 400;
  text-transform: uppercase;

  .ui-grid-cell-contents {
    padding: $line-height-computed/2 $grid-gutter-width/2;
  }
}

.ui-grid-header-cell {
  [role="button"] {
    cursor: default;
  }

  .sortable [role="button"] {
    cursor: pointer;
  }

  &.text-right {
    .ui-grid-cell-contents {
      display: flex;
      flex-direction: row-reverse;

      .ui-grid-header-cell-label {
        padding-left: 6px;
      }
    }
  }
}

.ui-grid-cell-checkbox {
  padding: $line-height-computed/2 $grid-gutter-width/2;

  md-checkbox {
    margin: 0;
  }
}

.ui-grid-cell-contents {
  outline: none;
  padding: $table-cell-padding;

  &.ui-grid-condensed {
  }
}

.ui-grid-pager-panel {
  color: $text-muted;
  font-size: $font-size-sm;

  abbr[title] {
    border: none;
    cursor: default;
  }

  button {
    border-radius: 0;
    padding: 0 10px;
  }

  .ui-grid-pager-first {
    border-right: 0;
    border-bottom-left-radius: $btn-border-radius-sm;
    border-top-left-radius: $btn-border-radius-sm;
  }

  .ui-grid-pager-next {
    border-left: 0;
  }

  .ui-grid-pager-last {
    border-left: 0;
    border-bottom-right-radius: $btn-border-radius-sm;
    border-top-right-radius: $btn-border-radius-sm;
  }
}

.ui-grid-pager-text {
  padding: 0 10px;
  position: relative;
  top: 1px;
}

.ui-grid-simple {
  .ui-grid-header {
    border: 0;
    color: $text-muted;
    font-size: $font-size-sm;
  }
  .ui-grid-row .ui-grid-cell {
    border: 0;
  }
  .ui-grid-header-cell {
    background-color: #fff;
    text-transform: initial;
  }
  .ui-grid-cell-contents {
    padding: 6px 15px;
  }
}
