html, body {
    min-height: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
}

.md-theme-default {
    a:not(.md-button) {
        &:hover {
            text-decoration: none;
        }
    }
}

md-content {
    background: none;
    color: $text-color;
}