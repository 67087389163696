.growl-container {
  > .growl-item {
    padding: $grid-gutter-width/2 $line-height-computed;
  }
}
.growl-emoticon {
  float: left;
  font-size: 16px;
  margin: 2px 10px 0 0;
}

.growl-error-message {
  font-size: 13px;
}
