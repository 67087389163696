.nav {

    > li {

        > a {
            color: $text-color;
        }
    }

    &.nav-sm {
        font-size: $font-size-sm;
        text-transform: uppercase;

        > li {

            > a {
                padding: 5px 10px;
            }
        }
    }
}

.nav-tabs {
    &.nav-tabs-default {

        > li {
            &:first-child {
                margin-left: $grid-gutter-width/2;
            }

            > a.active {
                background-color: $nav-tabs-default-active-link-bg;
                border-bottom-color: $nav-tabs-default-active-link-bg;
            }
        }
    }
}