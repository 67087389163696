.progress-steps {
  display: table;
  list-style: none;
  margin: $line-height-computed 0;
  padding: 0;
  width: 100%;

  li {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    //border-left-width: 0;
    border-width: 0 1px 0 0;
    display: table-cell;
    height: 10px;
    margin: 0;
    padding: 0;
    width: 33%;

    &:first-child {
      border-left-width: 1px;
    }

    &:after {
      background-color: $brand-alt;
      content: " ";
      display: block;
      height: 100%;
      width: 0;
      @include transition(400ms ease-out width 200ms);
    }

    &.complete {
      &:after {
        width: 100%;
      }
    }
  }

  &.three-steps {
    li {
      width: 33.33%;
    }
  }

  &.four-steps {
    li {
      width: 25%;
    }
  }

  &.five-steps {
    li {
      width: 20%;
    }
  }

  .modal-dialog & {
    margin: -1px 0 0px;
  }
}

.progress-steps-line {
  list-style: none;
  margin: $line-height-computed $grid-gutter-width * -1 $line-height-computed * -1;
  padding: $line-height-computed * 1.5 0 0;

  &:after {
    @extend .clearfix;
  }

  li {
    float: left;
    font-weight: $font-weight-bold;
    position: relative;
    text-align: center;
    width: 33.33%;

    span {
      position: relative;
      top: $line-height-computed * -2.4;
    }

    &:before {
      background: $input-border;
      border-radius: 50%;
      content: "";
      display: block;
      height: 15px;
      line-height: 15px;
      margin: 0 auto;
      position: relative;
      width: 15px;
      z-index: 1;
    }

    &.active {
      &:before,
      &:after {
        background: $accent-alt;
      }
    }

    &:after {
      background: $input-border;
      content: "";
      height: 3px;
      left: -50%;
      position: absolute;
      top: 6px;
      width: 100%;
    }

    &:first-child:after {
      content: none;
    }
  }
}
