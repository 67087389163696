md-radio-group {

    .form-group div[class^="col-"] & {
        margin: 6px 0;
    }

    .form-control {
        margin: -4px 0 0 $grid-gutter-width/2;
    }

    md-radio-button {
        margin: 8px 0;

        .layout-row & {
            margin-top: 0;
        }
    }
}

md-radio-button {
    .md-label {
        margin-left: 26px;
        vertical-align: top;
    }
}