md-toolbar {
  //border-bottom: 1px solid #e5e5e5;
  min-height: 60px;

  &[role="button"] {
    outline: none;
  }

  &.gray-light {
    background-color: #fafafa;
    color: $text-color;
  }
}

.md-toolbar-tools {
  height: 60px;

  h2,
  .h2 {
    .md-toolbar-subtitle {
      display: block;
      margin-top: 3px;
    }
  }

  h3,
  .h3 {
    font-weight: 300;
  }

  .md-icon-button {
    color: $text-muted;

    &.md-toolbar-back-button {
      margin: 0 0 0 -15px;
    }

    &.md-toolbar-settings {
      font-size: 22px;
    }
  }
}
