@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, .ie11 {
        display: block !important;
    } 
}

.login {

    .error-list {
        padding-bottom: 10px;
    }
}

.login-sso {

    .login-email {
        padding-right: 45px;
    }

    .activity-dots {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}