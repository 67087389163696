.table-responsive {
  //margin: 0 $grid-gutter-width/2*-1;
  width: auto;

  .panel & {
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    border-left: none;
    border-right: none;
  }
  .tr-hover:hover {
    background: darken($table-hover-bg, 1%);
  }
}

.table {
  margin-bottom: $line-height-computed;

  > thead > tr > th {
    border-bottom: 0;
    font-weight: normal;
    padding: 10px 15px;
    text-transform: uppercase;
  }

  &.align-middle td {
    vertical-align: middle;
  }

  tr {
    &:focus {
      outline: none;
    }

    &.row-active {
      background-color: $table-active-bg;
    }
  }

  .select-check {
    width: 40px;
  }

  .panel & {
    margin: 0;
  }
}

.table-sm {
  > tbody > tr > td {
    //font-weight: 300;
  }
}

.table-hover > tbody > tr:hover {
  .scrollable-ui {
    background: none;
  }
}

.table-logs {
  > tbody > tr > td {
    font-weight: 300;

    a {
      font-weight: bold;
    }

    a,
    span {
      margin: 0 -8px;
      padding: 4px 8px;
    }
  }
}

.table-simple {
  > tbody > tr > td {
    border: none;
  }

  &.table-sm {
    > tbody > tr > td {
      padding: 3px 30px 3px 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.table-small {
  font-size: $font-size-sm;

  & thead > tr > th {
    font-weight: $font-weight-bold;
  }
}

.table-width-auto {
  width: auto;
}

.table-hide-head {
  > thead {
    @extend .sr-only;
  }

  > tbody {
    tr:first-child td {
      border-top: none;
    }
  }
}

.table-head-border-only {
  > tbody {
    tr:not(:first-child) td {
      border-top: none;
    }
  }
}

.table-expanded {
  > tbody > tr > th,
  > tbody > tr {
    > td {
      padding: $line-height-computed * 1.5 $grid-gutter-width/1.5;
    }

    &:first-child {
      > td {
        border-top: none;
      }
    }
  }
}

.table-fields {
  margin: $line-height-computed * 2 0 0;
}

.table-actions {
  a {
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }

  & .btn-xs {
    margin: -4px 0 -2px;
  }

  .table-sm & .btn-xs {
    margin: -5px 0 -3px;
  }

  .btn-group {
    margin: -12px 0;
  }

  // Material Menu
  .md-menu {
  }

  .md-icon-button {
    margin: -17px 0;

    i {
      color: lighten($text-muted, 20%);
      font-size: 20px;
      margin-top: 3px;
    }
  }

  &.ui-grid-cell {
    .md-icon-button {
      margin: 10px 0 0;
    }
  }

  .table-sm & {
    .md-icon-button {
      padding: 0;
    }
  }
}

.on-row-hover {
  visibility: hidden;

  tr:hover &,
  .ui-grid-row:hover & {
    visibility: visible;
  }
}

.filename-column {
  max-width: 250px;
}

.show-more {
  i {
    margin-left: 2px;
    @include transition(200ms transform);
  }

  &.active i {
    transform: rotate(90deg);
  }
}

.row-icons {
  text-align: right;

  a {
    display: inline-block;
    line-height: 1em;
    margin: -4px 0;
    padding: 6px;
  }
}

.table-row-details {
  background-color: #fafafa;

  div {
    height: auto;
    opacity: 1;
    overflow: auto;
    padding: $table-cell-padding;
    padding-top: 0;

    &.ng-hide-add,
    &.ng-hide-remove {
      @include transition(opacity 0.2s ease-in);
    }

    &.ng-hide {
      opacity: 0;
    }
  }

  > td {
    border-top: none !important;
    padding: 0 !important;
  }

  table {
    background-color: transparent;
    font-size: $font-size-sm;
    width: 100%;
  }
}

.table-filters {
  margin-bottom: $line-height-computed;
}

.scrollable-cell {
  margin: -6px 0;
  max-height: 48px;
}
