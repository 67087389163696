.tooltip {
    top: 0;
}

.tooltip-icon {
    bottom: -2px;
    color: $text-muted;
    position: relative;

    &:before {
        content: "\e902";
        font-size: 18px;
    }
}

.tooltip-inner {
    a {
        color: $tooltip-color;
        text-decoration: underline;
    }
}