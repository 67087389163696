.datepicker-range {
  font-weight: 300;

  .datepicker-label {
    padding: 0 12px;

    &:first-child {
      padding-left: 0;
    }
  }

  .md-datepicker-input {
    font-size: $font-size-lg;
  }
}

.datepicker-label {
  display: inline-block;
  font-size: 16px;
  padding-right: 5px;
  position: relative;
  bottom: -2px;
}

.department-select {
  margin: 0;

  .md-text {
    font-size: $font-size-lg;
    font-weight: 300;
  }
}

.metric {
  border-radius: $border-radius-base;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  padding: 20px;

  &.metric-chart {
    .metric-value {
      display: inline;
    }
  }

  .usage-metrics & {
    margin: $line-height-computed/2 0;

    @media (min-width: $screen-md) {
      margin: $line-height-computed/2 0 $line-height-computed * 1.5;
    }
  }
}

.metric-value {
  display: block;
  color: rgb(85, 85, 85);
  font-weight: 300;
  font-size: 30px;
}

.metric-value-alt {
  font-size: 14px;
  font-weight: 400;
  left: -3px;
  position: relative;
  top: -6px;

  small {
    font-size: 10px;
  }
}

.metric-label {
  color: $text-muted;
  font-size: 16px;
}

.metric-bar {
  background-color: $gray-lighter;
  height: 12px;
  margin-top: 6px;

  > div {
    background-color: #7fd1ba;
    height: 100%;
    width: 0;

    @include transition(width 400ms 200ms ease-out);
  }
}

.usage-table {
  tr {
    cursor: default;

    .account-caret {
      color: $text-muted;
      font-size: $font-size-base;
      margin-left: $grid-gutter-width/4;

      i {
        @include transition(transform 200ms);
      }
    }

    &.group-row {
      cursor: pointer;
      position: relative;

      &.active {
        > td {
          padding-bottom: $line-height-computed/2;
        }

        .account-caret i {
          transform: rotate(180deg);
        }
      }
    }

    &.group-child-row {
      > td {
        border: none;
        padding: $table-cell-padding-sm;

        &.cell-name {
          font-size: 15px;
          padding-left: 37px;
        }

        .cell-metric-value {
          font-size: 15px;
        }

        .cell-metric-label,
        metric-bar {
          display: none;
        }
      }
    }

    .account-swatch {
      margin-bottom: 1px;
    }
  }
}

.cell-name {
  font-size: 16px;
}

.cell-metric {
  &.cell-metric-chart {
    .cell-metric-value {
      display: inline;
    }

    .metric-bar {
      height: 6px;
    }
  }
}

.cell-metric-value {
  display: block;
  font-size: $font-size-lg;
  line-height: 1.2;

  .cell-metric-value-alt {
    font-size: 11px;
    position: relative;
    top: -3px;

    small {
      //font-size: 9px;
    }
  }
}

.cell-metric-label {
  color: $text-muted;
  font-size: $font-size-sm;
  font-weight: 300;
}

.cell-message {
  .emoji {
    margin-bottom: ($message-emoji-size - $font-size-base)/-2;
    height: $message-emoji-size;
    width: auto;
  }
}
