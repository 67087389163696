.refresh-inbox {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.inbox {

    tr {
        color: $text-muted;

        &.read {
            background-color: #f9f9f9;
        }

        &.unread {
            background-color: #fff;
            color: $text-color;
            font-weight: 600;
        }

        &.selected {
            background-color: lighten(#ffc, 6%);
        }

        &.lockable {
            td {
                &.name,
                &.message {
                    @include transition(padding 150ms ease-out);
                    position: relative;
                }

                .locked-status {
                    font-size: $font-size-sm;
                    position: absolute;
                    top: 8px;
                }
            }
        }

        &.locked {
            td {
                &.name,
                &.message {
                    padding: 30px 15px 10px;
                }
            }
        }

        th {
            color: $text-color;
        }
    }

    th {
        .i-checkbox {
            border-color: $text-color;

            &.checked {
                border-color: $brand-alt;
            }
        }
    }

    td[ui-sref] {
        cursor: pointer;
    }

    td[role="button"]{
        outline: none;
    }

    td.message {
        position: relative;

        .message-oneline {
            left: 15px;
            line-height: 2;
            margin: -4px 0;
            right: 15px;
            overflow: hidden;
            position: absolute;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:before {
            content: '';
            display: inline-block;
        }

        .emoji {
            margin: -5px 0;
            height: 24px;
            width: auto;
        }
    }

    th.bullet,
    td.bullet {
        padding: 25px 0 0;
        width: 10px;
    }
}

.account-inbox {

    .i-checkbox-check.checked {
        background-color: $gray-light;
        border-color: $gray-light;
    }

    > * {
        padding: 0 $grid-gutter-width/2;

        @include media-breakpoint-up(sm) {
            padding: 0 $grid-gutter-width;
        }
    }

    .table {
        margin: 0;
    }

    .inbox-nav {
        box-shadow: $footer-nav-box-shadow;
        padding-bottom: $line-height-computed/2;
        padding-top: $line-height-computed/2;
        z-index: 1;
    }
}

.empty-message {
    color: $text-muted;
    font-size: $h3-font-size * $font-size-xs-ratio;
    font-weight: $font-weight-light;
    margin: $line-height-computed*3 0;
    text-align: center;

    @include media-breakpoint-up(sm) {
        font-size: $h3-font-size;
    }
}

.empty-message-text {
    margin-bottom: $line-height-computed;
}

.empty-message-details {
    font-size: $font-size-lg;
}

.i-bullet {
    background-color: $brand-alt;
    border-radius: 50%;
    display: block;
    height: 10px;
    margin: 0 auto;
    width: 10px;
}

.locked-status {
    color: #a5a5a5;
    font-style: italic;
    display: inline-block;
}
