html,
body {
  background-color: $body-bg;
  height: 100%;
}

body {
  @include transition(300ms ease-in background);

  position: relative;

  *:focus-visible {
    outline: 2px auto $accent-alt-a11y;
  }

  &.login {
    background-color: $accent;

    // Elevate
    //background: url(../images/content/bg_elevate-collage.jpg) no-repeat 50% 50%;
    //background-size: cover;
    //
    //.public .panel {
    //    box-shadow: 0 3px 18px 0 rgba(0,0,0,0.5);
    //}
    //
    //.copyright {
    //    text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    //}
    // Elevate
  }
}

.skip-link {
  background: #212529;
  color: #fff;
  font-weight: $font-weight-bold;
  line-height: 19px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -200%);
  overflow: hidden;
  border-radius: 15px;
  padding: 5px 15px;
  transition: transform 200ms ease-in-out;
  top: $navbar-height + 10;
  z-index: 1;

  &:hover,
  &:focus {
    color: #fff;
  }

  &:focus {
    outline: none;
    transform: translate(-50%, 0%);
  }
}

.clearfix {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

[role="button"] {
  cursor: pointer;
}

/* Errors */
.error-list {
  margin-top: 4px;
  padding: 0;
  padding: 0;
  list-style: none;
  color: #cc0000;
}

.error-list-bold {
  margin-top: 4px;
  padding: 0;
  padding: 0;
  list-style: none;
  color: #cc0000;
  font-weight: 500;
}

.disabled-ui {
  cursor: default;
  opacity: 0.5 !important;
}

.cursor-default {
  cursor: default;
}

.scrollable-ui {
  overflow: auto;
  background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), transparent),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), transparent) 0 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.status-bullet {
  animation-duration: 400ms;
  animation-fill-mode: both;
  background: $gray-400;
  border: 1px solid $gray-500;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: -2px 2px 0;
  width: 10px;
  vertical-align: middle;

  &.enabled {
    animation-name: statusPulseIn;
    animation-timing-function: ease-in-out;
    background: #7ed321;
    border-color: #6dc111;
  }
}

@keyframes statusPulseIn {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.6, 1.6, 1.6);
  }
  to {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}

.botpress--button-container--f1ib9x {
  display: none;
  visibility: hidden;
}

iframe#BeaconInlineArticlesFrame {
  z-index: 10000 !important;
}