.switch {
    background: $gray-lighter;
    border: 1px solid $input-border;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    overflow: visible;
    width: 30px;
    height: 15px;
    padding: 0;
    margin: 0;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
    transition: 0.2s ease-out all;
    -webkit-transition: 0.2s ease-out all;
    top: 3px;

    small {
        background: #fff;
        border-radius: 100%;
        box-shadow: 0 1px 3px rgba(0,0,0,0.4);
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.2s ease-out all;
        -webkit-transition: 0.2s ease-out all;
    }

    .switch-text {
        font-family:Arial, Helvetica, sans-serif;
        font-size:13px;
    }

    &.checked {
        background: $brand-alt;
        border-color: $brand-alt;

        .off {
            display:none;
        }

        .on {
            display:block;

        }

        small {
            left: 15px;
        }
    }

    &:focus {
        //@include tab-focus;
        outline: none;
    }

    .off {
        display:block;
        position: absolute;
        right: 10%;
        top: 25%;
        z-index: 0;
        color:#A9A9A9;
    }

    .on {
        display:none;
        z-index: 0;
        color:#fff;
        position: absolute;
        top: 25%;
        left: 9%;
    }

    &.wide {
        width:80px;

        &.checked small {
            left:52px;
        }
    }
    
    &.disabled {
        opacity: .50;
        cursor: not-allowed;
    }

    .checkbox & {
        margin-right: 5px;
    }
}

.switch-danger {
    &.checked {
        background: theme-color("danger");
        border-color: theme-color("danger");
    }
}