.progress {
    box-shadow: none;
    border-radius: 0;
    height: 10px;
    margin: $line-height-computed/3 0;
}

.progress-bar {
    box-shadow: none;
}

.progress-bar-success {
    background-color: $brand-alt;
}

.progress-bar-warning {
    background-color: #ffd03a;
}

.progress-bar-danger {
    background-color: theme-color("danger");
}