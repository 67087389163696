code, pre {
    font-family: $font-family-monospace;
}

code {
    background-color: #f5f5f5;
    border-radius: $input-border-radius;
    font-size: 90%;
    margin: -1px -2px;
    padding: 1px 2px;
}

pre {
    background-color: #f5f5f5;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    padding: 10px;
}

kbd {

}