md-tabs {

    &.md-default {
        box-shadow: $panel-box-shadow;

        md-tabs-wrapper {
            background: $panel-default-heading-bg;
        }
    }

    md-ink-bar {
        height: 4px;
    }

    fieldset {
        box-shadow: none;
    }

    md-tab-content {

    }

}