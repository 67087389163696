tags-input {
  .host {
    margin: 0;
  }

  .autocomplete {
    margin-top: -1px;
    padding: 0;

    .suggestion-list {
      max-height: 250px;
    }

    .suggestion-item {
      &,
      em {
        font-size: $font-size-sm;
      }

      &.selected {
        &,
        & em {
          background-color: $gray-lighter;
          color: $gray-900;
        }

        .opted-out {
          cursor: not-allowed;
        }

        .opted-out-label {
          font-style: italic;
          text-transform: uppercase;
        }
      }
    }
  }

  .tags {
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    border-radius: $border-radius-base;
    border: 1px solid #ccc;
    max-height: 124px;
    padding: 1px 6px;
    overflow: auto;

    &.focused {
      box-shadow: $input-focus-box-shadow;
      border-color: $input-focus-border-color;
    }

    .tag-item {
      background: #eee;
      border: none;
      font-family: $font-family-sans-serif;
      padding: 0px 0px 0px 8px;

      .remove-button {
        margin-left: 0px;
        opacity: 0.5;

        &.icon-close {
          font-size: 24px;
          line-height: 1;
          padding: 2px 8px;
          vertical-align: top;
        }
        &.icon-close:hover {
          opacity: 1;
          text-decoration: none;
        }
        &.icon-close:active {
          color: unset;
        }
      }

      &:hover {
        .remove-button {
          opacity: 1;
        }
      }

      &.selected {
        background: $brand-alt;
        color: #fff;

        .remove-button {
          color: #fff;
        }
      }
    }

    activity-dots {
      position: absolute;
      right: 10px;
      top: 6px;
    }
  }

  &.maxSelected {
    &.hideInput {
      .tags {
        border-color: transparent;
      }
    }

    &.hideAutoComplete {
      auto-complete {
        display: none;
      }
    }

    .input {
      display: none;
    }
  }

  &.single-tag {
    .tags {
      overflow: hidden;
    }
  }
}

.tag-template {
  .tag-body {
    display: inline-block;

    span,
    a {
      vertical-align: top;
    }

    .additional-buttons {
      //display: none;
      //padding-left: 4px;
    }

    &:hover {
      .additional-buttons {
        //display: inline-block;
      }
    }
  }
}

#campaign .tags {
  padding-right: 45px;
}
