.btn {
  font-family: $font-family-sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &:focus,
  &.active:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }

  &.btn-xs {
    padding: 2px 6px;
  }

  &.btn-sm {
    letter-spacing: 0;
  }

  &.btn-lg {
    font-size: 15px;
    font-weight: 300;
    padding-left: $grid-gutter-width/1.5;
    padding-right: $grid-gutter-width/1.5;

    i {
      font-size: 16px;
      margin: 0 3px 0 -8px;
    }
  }
}

.btn-light {
  border-color: $gray-300;
}

.btn-white {
  @include button-variant(#fff, #fff);
}

.btn-blue {
  @include button-variant($blue, $blue-dark);
  border: none;
}

.btn-alt {
  @include button-variant(#fff, $brand-alt, $brand-alt);
}

.btn-spacer {
  cursor: inherit;
}

.btn-close {
  color: #fff;
  background-color: #bbb;
  border: none;
  border-radius: 3px;
  font-size: 28px;
  height: 20px;
  overflow: hidden;
  padding: 2px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 1;

  @include transition(background 200ms ease-in-out);

  &.icon-close {
    font-weight: bold;
    line-height: 15px;
  }

  &.btn-sm {
    font-size: 19px;
    height: 16px;
    padding: 0 0 1px;
    width: 16px;
  }

  &:hover {
    background-color: $brand-alt;
  }
}

.btn-link {
  &:focus {
    text-decoration: none;
  }

  i {
    margin-right: 6px;
  }
}
