$public-border-radius: 4px;

.public {
  .card,
  .alert {
    border: 0;
    border-radius: $public-border-radius;
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.25);
    margin: $line-height-computed auto $line-height-computed * 1.5;
    max-width: 720px;
  }

  .card {
    overflow: hidden;

    a {
      text-decoration: underline;
    }
  }

  .card-body {
    padding: 0;

    section,
    aside {
      border-radius: 0 0 $public-border-radius $public-border-radius;
      padding: $grid-gutter-width $grid-gutter-width/2;

      @include media-breakpoint-up(xs) {
        border-radius: 0 $public-border-radius $public-border-radius 0;
        padding: $grid-gutter-width;
      }
    }

    section {
      h4,
      .h4,
      p {
        color: $text-muted;
        margin: 0 0 $line-height-computed * 1.5;

        & + p {
          margin-top: $line-height-computed/-1.5;
        }
      }
    }

    aside {
      background-color: $gray-lightest;

      ul {
        margin-bottom: 7px;
        li {
          margin: $line-height-computed/3 0;
        }
      }
    }

    .aside-header {
      color: $text-muted;
      font-size: $font-size-lg;
      font-style: italic;
      font-weight: $font-weight-light;
      text-align: center;

      @include media-breakpoint-up(sm) {
        margin: $line-height-computed 0 $line-height-computed * 2;
      }

      .emoji {
        margin-bottom: ($message-emoji-size - $font-size-base)/-2;
        height: $message-emoji-size;
        width: auto;
      }
    }

    .aside-message {
      background-color: theme-color-level("warning", $alert-bg-level);
      border-radius: $public-border-radius;
      color: $text-muted;
      padding: $line-height-computed/2 $grid-gutter-width/2;
    }
  }

  .btn {
    border-radius: $public-border-radius;
  }
}

.public-logo {
  margin: $line-height-computed 0 $line-height-computed * 2;
  text-align: center;

  img {
  }
}

.copyright {
  color: rgba(255, 255, 255, 0.7);
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  margin-bottom: $line-height-computed;
  text-align: center;
}
