.out-of-office {
  textarea {
    resize: none;
    height: 160px;
  }

  .status {
    text-transform: uppercase;
    font-weight: 600;
    font-style: italic;
  }

  .off {
    opacity: 0.75;
  }

  .form-text {
    margin-bottom: 30px;
  }

  .notification {
    position: absolute;
    top: 25px;
    right: 30px;
    width: 315px;
    background: white;
    border-radius: 3px;
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14),
      0 4px 18px 3px rgba(0, 0, 0, 0.12);
    padding: 25px 25px 25px 20px;
    width: 315px;
    z-index: 60;

    .message {
      width: 210px;
    }

    p {
      font-size: 15px;
    }

    .close {
      padding: 8px 12px;
      right: 0;
      top: 0;
      float: right;
    }

    @include media-breakpoint-only(xs) {
      top: 10px;
      right: 10px;
      width: 300px;

      .message {
        width: 200px;
      }
    }
  }

  .notification-new {
    position: absolute;
    top: 25px;
    right: 30px;
    width: 490px;
    background: white;
    border-radius: 3px;
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14),
      0 4px 18px 3px rgba(0, 0, 0, 0.12);
    padding: 24px;
    z-index: 60;

    .ooo-container {
      max-height: 450px;
      overflow-y: auto;
    }

    .close {
      padding: 8px 16px;
      right: 0;
      top: 0;
      position: absolute;
    }

    .list-group {
      .list-group-item {
        padding-bottom: 10px;
      }
    }

    @include media-breakpoint-only(xs) {
      //this still wraps the toggle at 350px, but that's narrower than any devices in 2023
      padding: 20px;
      top: 10px;
      width: 95%;
      left: -45%;
      transform: translateX(50%);

      .list-group {
        .list-group-item {
          padding-right: 0;
        }
      }
    }
  }
}
