//.panel {
//    box-shadow: $panel-box-shadow;
//    margin: $panel-body-padding 0 $panel-body-padding*2;
//    border: 0;
//
//    &.panel-sm {
//        box-shadow: none;
//        border: 1px solid $panel-inner-border;
//        margin: $line-height-computed 0;
//
//        .panel-heading {
//            font-weight: 300;
//            padding: $modal-header-padding-y $modal-header-padding-x;
//        }
//
//        .panel-body {
//            padding: $padding-small-vertical $padding-small-horizontal;
//        }
//
//        .empty-message {
//            margin: $line-height-computed 0;
//        }
//    }
//
//    .panel {
//        margin: $line-height-computed 0;
//    }
//}

//.panel-heading {
//
//    .btn {
//        margin: ($padding-small-vertical+2)*-1 $padding-small-horizontal*-1;
//    }
//
//    .md-button {
//        margin: $line-height-computed/-2 0;
//    }
//
//    .nav {
//        margin: ($padding-small-vertical+1)*-1 $padding-small-horizontal*-1;
//    }
//}

.card-body {
  .empty-message {
    font-size: $font-size-lg;
    font-style: italic;
    font-weight: $font-weight-light;
    margin: $line-height-computed/2 0;
  }
}

.panel-loading {
  @extend .modal-loading;
}

.panel-grid {
  position: relative;

  .filter-row {
    margin: $grid-gutter-width/2 $line-height-computed;
    min-height: 36px;

    md-menu {
      .md-button {
        margin: 0;
      }
    }
  }

  .ui-grid-cell-contents {
    padding: $table-cell-padding-sm;
  }

  .ui-grid-pager-panel {
    background: $panel-default-heading-bg;
    min-height: 25px;
    padding: 5px 0;
    position: static;
    bottom: auto;
  }

  .empty-message {
    font-size: $font-size-lg;
    font-style: italic;
    margin: $line-height-computed * 3 0;
    position: static;
    top: auto;
  }
}

.panel-list-header {
  h4,
  .h4 {
    font-size: $font-size-lg;
    margin: $line-height-computed/2 0 $line-height-computed;

    i {
      color: $text-muted;
      font-size: 16px;
      margin-right: 3px;
    }
  }
}

.panel-list-hover {
  .panel-list-item:hover {
    background: darken($table-hover-bg, 1%);
  }
}

.panel-list-item {
  background: $panel-default-heading-bg;
  border-radius: 3px;
  font-weight: 300;
  margin: $line-height-computed/2 0;
  padding: $grid-gutter-width/2;

  & + & {
    //border-top: 1px solid $panel-default-border;
  }
}

.has-empty-state {
  background: $panel-default-heading-bg;

  .panel-body {
    height: 100%;
    padding: 0;
  }
}

.empty-state {
  margin: $grid-gutter-width * 2 $grid-gutter-width;
}

.empty-state-text {
  font-size: $font-size-lg;
  font-weight: $font-weight-light;
  margin-bottom: $line-height-computed;

  h4,
  .h4 {
    font-weight: $font-weight-normal;
    margin-top: 0;
  }
}

.empty-state-image {
  margin: $line-height-computed $grid-gutter-width * 1.5 $line-height-computed 0;

  &.with-border {
    background: transparentize(#fff, 0.25);
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    min-height: 90px;
    padding: $line-height-computed/2 $line-height-computed;
  }
}

.empty-state-subtext {
  font-style: italic;
  font-weight: 300;
  margin-bottom: 20px;
}
