#usage-header {

    .contract-term {
        background-color: #f5f5f5;
        padding: $line-height-computed/2 $grid-gutter-width/2;

        @include media-breakpoint-up(sm) {
            float: right;
        }
    }
}