.md-nav-bar {
  .md-nav-item .md-active {
    color: $text-color;
  }

  md-nav-ink-bar {
    height: 4px;
  }

  .group-account-nav-bar & {
    .md-button.md-accent {
      color: $text-color;
    }
  }

  @include media-breakpoint-down(sm) {
    height: auto !important;

    md-nav-ink-bar {
      display: none;
    }

    ._md-nav-bar-list {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }
}

.md-nav-item {
  &:first-of-type {
    margin-left: 0;
  }

  .md-button._md-nav-button {
    margin: 0;
  }
}

.nav-bar-header {
  .md-nav-bar {
    height: 60px;
  }
  .md-button._md-nav-button {
    line-height: 36px;
  }
}
