.number-selection {
  max-height: 600px;
  opacity: 1;

  &.ng-hide-add,
  &.ng-hide-remove {
    @include transition(all 0.6s ease-out);
    overflow: hidden;
  }

  &.ng-hide {
    max-height: 0;
    opacity: 0;
  }
}

.number-selection-body {
  background: $nav-tabs-default-active-link-bg;
  padding: $grid-gutter-width/2;

  .number-search-results {
    //padding: 0;
    margin: 0 $grid-gutter-width/-2;
  }
}

.form-phone {
  color: $text-muted;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: $line-height-computed/2;

  .form-group {
    margin: 0;
  }

  .form-control {
    font-size: 16px;
    letter-spacing: 1px;
    margin: 0 5px;
    width: 60px;

    &.phone-number-input {
      margin-left: 0;
      width: 145px;
    }

    &.phone-line-number-input {
      width: 60px;
    }
  }

  .btn {
    margin-left: $grid-gutter-width/2;
  }
}

.phone-number-input {
  width: 110px;
}

.phone-number-input-lg {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  width: 150px;
}

.phone-line-number-text {
  display: inline-block;
  letter-spacing: 2px;
  margin-right: 5px;
  padding: 3px 0;
  vertical-align: middle;
}

.number-search {
  //margin: $grid-gutter-width/2 0;
  //padding: $grid-gutter-width/2;
}

.number-search-results {
  background-color: #fafafa;
  max-height: 140px;
  overflow: auto;
  padding: 0 $grid-gutter-width/2 10px;

  .form-group {
    margin: $line-height-computed/2 0;
  }

  .label {
    margin-bottom: 0;
    margin-top: $line-height-computed/2;
  }
}

.number-selected {
  background-color: #fafafa;
  margin: $grid-gutter-width/2 0;
  padding: $line-height-computed/4 $grid-gutter-width/2;

  a {
    font-weight: 300;
  }

  h4,
  .h4 {
    font-family: $font-family-sans-serif;
    margin: 5px 0;
  }
}

.department-selection-table {
  min-width: 55%;
  width: auto;

  > tbody > tr > td {
    border: none;
    padding: 0px 30px 0 0;
  }
}

.group-account-selection {
  padding-left: 67px;
  background: #fafafa;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.group-account-note {
  margin-top: 10px;
}

.user-search {
  md-autocomplete {
    input:not(.md-input) {
      height: $input-height; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
      padding: $input-padding-y $input-padding-x;
      font-size: $font-size-base;
      line-height: $line-height-base;
    }
    md-autocomplete-wrap {
      height: auto;
    }
  }
}

.create-user.md-virtual-repeat-container {
  &.md-autocomplete-suggestions-container {
    z-index: 1051;
    .md-autocomplete-suggestions {
      li {
        height: auto;
        line-height: 18px;
        padding: 10px 15px;
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
}

.existing-user {
  background: $gray-light-bg;
  margin: $line-height-computed 0;
  padding: $line-height-computed;

  a {
    font-weight: $font-weight-light;
  }

  h4,
  .h4 {
    margin: $line-height-computed/2 0 4px;
  }
}

.create-user.md-virtual-repeat-container.md-autocomplete-suggestions-container .md-autocomplete-suggestions li {
  padding: 0;
}
.md-autocomplete-suggestions li {
  padding: 0;
}

.md-autocomplete-suggestions li div {
  padding: 10px 15px;
}
.newUser {
  border-top: 1px solid #ccc;
  .fa-plus {
    float: left;
    padding: 15px 15px 0 0;
    color: #000;
  }
}

.soft-alert-text {
  color: #555;
  font-weight: 300;
  line-height: 20px;
}
