.avatar {
  background-color: $blue;
  border-radius: 50%;
  display: inline-block;
  position: relative;

  img {
    position: relative;
  }

  .navbar & {
    margin: 1px 0;
  }
}

.avatar-letter {
  color: rgba(255, 255, 255, 0.85);
  font-family: $font-family-alt;
  font-size: 40px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 10px;
}

.avatar-alt {
  background-color: #b3b3b3;

  .avatar-letter {
    color: rgba(0, 0, 0, 0.85);
  }
}

.avatar-sm {
  .avatar-letter {
    font-size: 14px;
    top: 2px;
  }
  img {
    height: 24px;
    width: 24px;
  }
}

.avatar-md {
  .avatar-letter {
    font-size: 18px;
    top: 2px;
  }
  img {
    height: 32px;
    width: 32px;
  }
}

.avatar-lg {
  .avatar-letter {
    font-size: 20px;
    top: 6px;
  }
  img {
    height: 42px;
    width: 42px;
  }
}

.avatar-bordered {
  border: 2px solid #fff;
}

.caretaker-avatars {
  height: 48px;
  position: relative;

  .avatar {
    &:first-child {
      left: 1px;
      position: absolute;
      top: 4px;
      z-index: 1;
    }
    &:last-child {
      bottom: 4px;
      position: absolute;
      right: 1px;
    }
  }
}

.grid-avatars {
  margin-right: 12px;
  min-width: 48px;
}

.avatar-desert {
  color: #baa99c;
}

.avatar-desert-background {
  background-color: #baa99c;
}

.avatar-seafoam {
  color: #88c9cb;
}

.avatar-seafoam-background {
  background-color: #88c9cb;
}

.avatar-cornwall {
  color: #8fadc2;
}

.avatar-cornwall-background {
  background-color: #8fadc2;
}

.avatar-lilac {
  color: #a0a0bb;
}

.avatar-lilac-background {
  background-color: #a0a0bb;
}

.avatar-mulberry {
  color: #bf97a6;
}

.avatar-mulberry-background {
  background-color: #bf97a6;
}

.avatar-ochre {
  color: #c7998a;
}

.avatar-ochre-background {
  background-color: #c7998a;
}

.avatar-blonde {
  color: #c1b07f;
}

.avatar-blonde-background {
  background-color: #c1b07f;
}

.avatar-sage {
  color: #80ac97;
}

.avatar-sage-background {
  background-color: #80ac97;
}
