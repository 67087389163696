.report {

    .panel {
        margin-bottom: 0;
        margin-top: 0;

        &.mb {
            margin-bottom: $line-height-computed*1.5;
        }

        @include media-breakpoint-up(md) {
            &.mb-0-gt-sm {
                margin-bottom: 0;
            }
        }

    }

    .mb {
        margin-bottom: $line-height-computed*1.5;
    }

    @include media-breakpoint-up(md) {

        .ml-gt-sm {
            margin-left: $grid-gutter-width;
        }
        .mr-gt-sm {
            margin-right: $grid-gutter-width;
        }
    }

}

.report-metrics {
    margin-bottom: $line-height-computed*2;

    .metric {
        margin: $line-height-computed/4 0;
        padding-left: $grid-gutter-width/6;
        padding-right: $grid-gutter-width/6;
        text-align: center;


        & + .metric {
            @include media-breakpoint-up(sm) {
                margin-left: $line-height-computed/2;
            }

            @include media-breakpoint-up(md) {
                //margin-left: 0;
            }

            @include media-breakpoint-up(lg) {
                margin-left: $line-height-computed;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin: 0;
    }
}

.report-details {

    p {
        margin-bottom: $line-height-computed/3;
    }

    @include media-breakpoint-up(md) {
        //padding: $line-height-computed/2 0;
    }
}

.report-description {
    max-height: none;

    @include media-breakpoint-up(md) {
        max-height: 60px;
        padding-right: $grid-gutter-width/2;
    }

}

highchart {
    display: block;
    max-width: 100%;
    width: 100%;

    .panel-body & {
        margin-bottom: $line-height-computed*-.5;
    }
}

.sent-detail-grid-container {
    height: 400px;
}

.send-reply-category-field {

    md-checkbox {
        margin: $line-height-computed/4 0;
    }

    .message-preview {
        margin-left: $grid-gutter-width;
    }
}