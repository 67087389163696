md-menu-content {
    max-height: 320px;
    padding: 0;
}

md-menu-item {
    height: auto;
    min-height: 0;

    > .md-button,
    > a.md-button {
        line-height: $line-height-base;
        min-height: 0;
        padding: 15px 20px;

        span {
            font-size: $font-size-sm;
        }
    }
}

._md-open-menu-container md-menu-divider {
    margin-top: 0;
    margin-bottom: 0;
}