﻿.modal-header {
  .md-button {
    margin-right: 16px;
    margin-top: 0;
  }

  .close {
    float: none;
    right: 30px;
    position: absolute;
  }
}

.modal-body {
  min-height: 80px;

  h4,
  .h4 {
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

.modal-footer {
  justify-content: space-between;
  padding: 15px 30px;
}

.modal-loading {
  padding: $line-height-computed * 3 0;
  text-align: center;

  i {
    color: $text-muted;
    font-size: 24px;
  }
}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    &.criteria-preview-modal {
      margin-top: 60px;
      width: 400px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    margin: 75px auto;

    &.criteria-preview-modal {
      margin-top: 100px;
    }
  }
}
