md-sidenav {
  background-color: #f2f2f3;

  &.main-nav {
    width: $sidebar-width;
    min-width: auto;
  }
}

md-backdrop.md-opaque {
  @media (min-width: $screen-md) {
    display: none;
  }
}

.main-nav {
  @include transition(margin-top 200ms ease-in-out);

  md-content {
    background-color: transparent;
    width: $sidebar-width;

    &.sidenav-bottom {
      box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.03);

      &.no-shadow {
        box-shadow: none;
      }

      ul li a {
        padding-bottom: 17px;
        padding-top: 17px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      position: relative;

      a {
        color: $text-color;
        display: block;
        padding: $line-height-computed/1.5 $grid-gutter-width;

        .md-truncate {
          padding-right: 5px;
        }

        &:hover {
          background-color: #e4e4e5;
          text-decoration: none;
        }

        &.disabled {
          color: $text-muted;
          cursor: default;
          font-weight: $font-weight-light;

          &:hover {
            .tag {
              opacity: 1;
            }
          }
        }

        .tag {
          @include transition(opacity 300ms ease-in);
          background-color: $gray-light;
          color: #fff;
          font-weight: $font-weight-light;
          margin-left: 3px;
          opacity: 0;
          padding: 3px 4px 4px;
          position: relative;
          top: -2px;
        }

        i {
          @include transition(transform 200ms);
          float: right;
          margin-top: 3px;
        }
      }

      &.active {
        > a {
          background-color: #e4e4e5;
          border-left: 5px solid $brand-alt;
          font-weight: 600;
          padding-left: $grid-gutter-width - 5;

          .account-swatch {
            display: none;
          }
        }
      }

      &.hasChildren {
        .sub-menu {
          @include transition(height 200ms ease-in);

          height: 0;
          overflow: hidden;
        }

        ul {
          li {
            &.active a {
              padding-left: $grid-gutter-width * 1.5-5;
            }
          }

          a {
            padding: $line-height-computed/3 $grid-gutter-width/2 $line-height-computed/3 $grid-gutter-width * 1.5;
          }
        }

        &.open {
          background-color: #ebebec;

          i {
            transform: rotate(180deg);
          }
        }
      }

      &.divider {
        border: {
          color: #e4e4e5;
          style: solid;
          width: 1px 0 0;
        }

        &.top {
          border-width: 0 0 1px;
          padding: $line-height-computed * 1 0;
        }

        &.bottom {
          //display: none;
        }
      }

      &.light {
        border-top: 1px solid rgba(228, 228, 229, 1);

        & > a {
          background-color: #fafafa;
        }

        &.active {
          border-top: 0;

          > a {
            background-color: #e4e4e5;
            border-color: theme-color("danger");
          }
        }
      }

      .new-badge {
        position: absolute;
        right: 1rem;
        top: 1rem;
        padding: 2px 10px 2px 10px;
        background-color: theme-color("secondary");
        color: #fff;
        border-radius: 1rem;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
        line-height: 1.6;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &:not(.md-locked-open) {
      //margin-top: $navbar-height;
    }

    .hasImpersonateBanner &:not(.md-locked-open) {
      //margin-top: $navbar-height;
    }
  }
}

.sidenav-action {
  padding: $line-height-computed/1.5 $grid-gutter-width;
}

.appcues-checklist-spacer {
  display: block;
  height: 48px;
}

#nav-chat-disabled {
  cursor: not-allowed;

  .lock-icon {
    margin-top: 1px;
    color: #848C94;
    padding: 2px;
    font-size: 20px;
    position: relative;
    bottom: 1px
  }
}
