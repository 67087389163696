.dropdown-menu {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
  margin-top: -1px;

  .profile-menu & {
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 0;
    margin-top: 8px;
  }

  .btn-group-sm & {
    .dropdown-item {
      font-size: $font-size-sm;
    }
  }

  &.dropdown-scrollable {
    overflow: auto;
  }

  li {
    .dropdown-item {
      border-radius: 0;
    }
  }
}

.dropdown-toggle {
  &::after {
    font-size: $font-size-base;
    position: relative;
    top: 1px;
  }
}
