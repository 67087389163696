.dashboard {
  .page-content {
    padding-top: $line-height-computed/2;
  }

  .alert {
    cursor: pointer;
    margin: $line-height-computed 0 0;

    h4,
    .h4 {
      margin-bottom: 3px;
    }

    .icon {
      margin: 0;
      padding: $line-height-computed/4 $grid-gutter-width/2 0 0;
      vertical-align: top;
    }
  }

  h3,
  .h3 {
    &.dashboard-module-title {
      //font-size: $h4-font-size;
      margin: 0 0 $line-height-computed;
    }
  }

  .filter-row {
    margin-bottom: $line-height-computed/2;
  }

  .metric-bar {
    > div {
      background-color: #396fb0;
    }
  }

  .ui-grid-viewport {
    height: auto !important;
  }

  .empty-message {
    font-size: $font-size-base;
    padding: $line-height-computed * 2 0;
  }
}

.dashboard-module {
  & + & {
    margin-bottom: $grid-gutter-width/2;
  }
}

campaigns {
  .time-filter-only {
    margin-top: $grid-gutter-width;
  }
  .panel-body {
    &.loading {
      min-height: 159px;
    }
  }
}

.dashboard-filters {
  &.time-filter-only {
    display: flex;
    justify-content: space-between;
  }
}

.dashboard-module-footer {
  font-size: $font-size-sm;
  height: $line-height-computed;
  padding: 0 $grid-gutter-width/4;
  text-align: right;
}

.panel-container {
  margin: 0 $grid-gutter-width/-2;

  .metric,
  .panel,
  .card {
    margin: $grid-gutter-width/2;
  }
}
