.conversation-list-column {
    max-width: 400px;
    min-width: 275px;
    width: 30%;
    .contact-card-open {
        @media (min-width: $screen-md) and (max-width: $screen-lg) {
            width: 64px;
            max-width: none;
            min-width: 64px;
        }
    }
}

.conversation-item-column {
    width: 70%;
    @media (max-width: $screen-md) {
        width: 0;
    }
}