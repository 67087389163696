#app-reload {
    box-shadow: 0 0 8px rgba(0,0,0,.4);
    background: #f5f5f5;
    bottom: 0;
    left: 0;
    line-height: 1.5;
    min-width: $sidebar-width;
    max-width: 100%;
    position: fixed;
    width: 100%;
    z-index: 1100;

    @include media-breakpoint-up(sm) {
        bottom: 8px;
        left: 10px;
        width: 320px;
    }

    .content {
        font-weight: normal;
        padding: $line-height-computed/2 $grid-gutter-width/2;
    }

    .emoji {
        height: 18px;
        margin: -3px 0;
        width: auto;
    }

    small {
        display: inline-block;
        line-height: 1.5;
        margin-top: 3px;
    }
}