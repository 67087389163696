.timepicker {
    width: auto;

    tbody {
        border: none;
    }

    tr {
        border: none;
    }

    td {
        padding: 0 2px;
        border: none;
        vertical-align: middle;
    }
}