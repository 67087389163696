h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    //margin-top: $line-height-computed;
    //margin-bottom: $line-height-computed / 1.5;

    small,
    .small {
        color: $text-muted;
        font-family: $font-family-sans-serif;
    }
}

h1, .h1,
h2, .h2,
h3, .h3 {

    small,
    .small {
        font-size: 60%;
    }
}

h4, .h4,
h5, .h5,
h6, .h6 {
}

b, strong, .text-bold {
    font-weight: $font-weight-bold;
}

.text-normal {
    font-weight: $font-weight-normal !important;
}

.text-weight-light {
    font-weight: $font-weight-light;
}

.text-base {
    font-size: $font-size-base;
}

.text-large {
    font-size: $font-size-lg;
}

.text-small {
    font-size: $font-size-sm;
}

.text-muted {
    font-weight: $font-weight-light;
}

.text-break-word {
    word-break: break-word;
}

.text-wrap {
    white-space: normal;
}

.accent-text {
    color: $accent
}

.no-wrap {
    white-space: nowrap;
}

.success {
    color: theme-color("primary");
}

.danger {
    color: theme-color("danger");
}

.gold {
    color: gold;
}

ul.list-bulleted {
    list-style-type: none;
    padding-left: .5em;

    li {
        margin-bottom: .4em;
        padding-left: .9em;

        &:before {
            content: '\2022';
            margin-left: -.8em;
            padding-right: .4em;
        }
    }
}

.list-fields-inline {
    font-family: $font-family-monospace;
    list-style-type: none;
    padding: 0;

    li {
        background: #eee;
        border-radius: 3px;
        display: inline-block;
        margin: 2px;
        padding: 2px 8px;
    }
}

pre {

    .form-group [class*='col-'] & {
        margin: 0;
    }
}

.more {
    border-bottom: 1px dotted $text-muted;
}