.new-template {

    .text-description {
        margin-right: 20px;
    }

    .helper-trigger {
        position: relative;
        float: right;
    }
}