.btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.custom-field-btn-dropdown {
    .btn {
        font-family: $font-family-sans-serif;
        text-transform: none;
    }
}

.btn-group {
    label.btn {
        font-family: $font-family-sans-serif;
        text-transform: none;
    }

    &.no-border {
        > .btn {
            border: 0;
            border-radius: $border-radius-base !important;
        }
    }
}

.btn {

    .caret {
        color: $text-muted;
        margin-left: 1px;
    }

    &.btn-alt {
        .caret {
            color: #fff;
        }
    }

    &.btn-sm {
        .caret {
            margin-top: -1px;
        }
    }
}