.search-form {
  min-width: 170px;
  position: relative;
  width: 35%;

  //Legacy Pages
  .page-head & {
    display: inline-block;
    margin-left: 25px;
    top: -10px;
  }

  input {
    border: 1px solid $input-border;
    border-radius: $border-radius-base;
    box-shadow: none;
    box-sizing: border-box;
    opacity: 1;
    padding: 8px 5px 7px 34px;
    width: 100%;
    z-index: 1;
    @include transition(all 0.3s ease);
  }
}

.search-form input:focus {
  box-shadow: none;
  outline: none;
}

.search-form .fa-search {
  color: $gray-light;
}

.search-form .fa-times {
  color: crimson;
}

.search-form-icon {
  border: 0;
  padding: 8px 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 2;

  &.search-form-icon-right {
    left: auto;
    right: 0;
  }
}

.search-advanced {
  margin-top: $line-height-computed;
}

.search-results-count {
  color: $text-muted;
  font-size: $font-size-sm;
  margin-left: 4px;
  position: absolute;

  .search-advanced & {
    margin-top: -8px;
  }
}

.empty-search {
  font-size: $h4-font-size;
  padding: $line-height-computed $grid-gutter-width/2;

  .emoji {
    font-size: $h3-font-size;
  }
}

.filter-row {
  margin-bottom: $line-height-computed;

  md-switch {
    margin: 0;
  }

  md-select {
    margin: 0 $grid-gutter-width/2;

    & + md-select {
      margin-left: $grid-gutter-width/6;
    }
  }

  .search-form {
    .fa-search {
      //top: 8px;
    }

    input {
      //height: $input-height-small;
    }
  }
}
