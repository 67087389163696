.tags {

    .tag {
        background: #eee;
        border-radius: 3px;
        cursor: default;
        display: inline-block;
        font-size: $font-size-base;
        line-height: 25px;
        margin: 2px;
        padding: 0 8px;

        .icon-close {
            color: $text-color;
            font-size: 24px;
            font-weight: bold;
            line-height: 1;
            margin-left: 4px;
            opacity: .5;
            padding: 2px;
            vertical-align: middle;
        }

        &:hover {
            .icon-close {
                opacity: 1;
            }
        }
    }
}