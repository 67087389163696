.popover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: $font-size-sm;

  &.popover-emoji-picker {
    border-radius: 4px;
    max-width: 100%;
    width: 344px;

    .popover-body {
      padding: 0;
    }
  }

  .popover-body {
    padding: 10px 15px;
    width: 264px;

    ul {
      margin: 0;
      padding: 0;
    }

    .popover-title {
      margin: -10px -15px 10px;

      .btn {
        float: right;
        margin: -3px 0;
        padding: 3px 6px 2px;
      }
    }
  }

  .popover-action {
    margin-top: $line-height-computed/2;
    text-align: center;
  }

  &.popover-modal {
    .arrow {
      display: none;
    }

    .popover-inner {
      max-width: 100%;
      width: 300px;
    }

    .popover-content {
      padding: $line-height-computed $grid-gutter-width/2;
    }
  }
}

.popover-title {
  background-color: #f2f2f3;
  border: 0;
  border-radius: 2px 2px 0 0;
  font-size: $font-size-sm;
  font-weight: 600;
  margin: -1px -1px 0;
  padding: 10px 15px;
  text-transform: uppercase;
}

.popover-overflow {
  margin: -10px -15px;
  max-height: 283px;
  min-height: 35px;
  min-width: 90px;
  overflow: auto;

  i {
    left: 50%;
    margin: -12px 0 0 -12px;
    position: absolute;
    top: 50%;
  }
}

ul.popover-list {
  list-style-type: none;
  overflow: auto;
  margin: 0;
  padding: 0;

  li {
    padding: $line-height-computed/2 $grid-gutter-width/2;

    & + li {
      border-top: 1px solid $table-border-color;
    }

    &:hover {
      background-color: $table-hover-bg;
    }
  }
}

.popover-link {
  &:focus {
    outline: none;
  }

  i {
    color: $text-muted;
    font-size: $font-size-sm;
  }
}

.popover-empty {
  padding: $line-height-computed 0;
}

.popover-empty-image {
  margin-bottom: $line-height-computed;
}

.popover-empty-text {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  margin-bottom: $line-height-computed;
  text-align: center;
}
