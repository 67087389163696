﻿.import-container {
    border: 1px solid $accent;
    margin-top: 34px;
    padding: 15px 30px;
    
    > div {
        padding: 0;
    }
}

.import-step {
    transition: all .5s ease;
}

.import-title {
    color: $accent;
    font-family: 'Raleway';
    font-weight: 600;
    margin: 0 0 5px;
}

.import-step--detail {
    color: #aaa;
    font-family: 'Raleway';
    font-size: 12px;
    padding-right: 5px;
    line-height: 1.5em;
}

.import-disclaimer {
    font-family: 'Raleway';
    font-size: 10px;
    color: #aaa;
    line-height: 1.5em;
}

.import-grid {
    clear: both;
    font-size: 0;
    width: 100%;
}

.import-grid--item {
    border: 1px solid #ccc;
    display: inline-block;
    font-size: 14px;
    height: 215px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 200px;

    transition: all .5s ease;
}

.import-paste {
    border: 1px solid #ccc;
    width: 100%;
    height: 210px;
    margin-bottom: 13px;
}

.column-header {
    height: 73px;
    padding: 20px 10px 15px;
    position: relative;
    transition: all .5s ease;
}

.column-header--select {
    margin-bottom: 6px;
    width: 100%;
}

.column-header--button {
    padding: 2px;
    width: 100%;
}

.column-name {
    color:#000;
    font-family: 'Raleway';
    font-weight: 500; 
    margin-top: 0;
    margin-bottom: 0;
}

.column-name--link {
    -webkit-appearance: none;
            appearance: none;
    background: none;
    border: none;
    color: $accent;
    font-size: 12px;
    text-decoration: underline;
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
}

.column-text-item {
    color: #aaa;
    font-size: 12px;
    margin: 0;
}

.column-content {
    height: 140px;
    overflow: auto;
}

.column-content--item,
.column-content--title {
    padding: 5px 20px;
}

.column-content--title {
    background: #eaeaea;
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;

    transition: all .5s ease;
}

.column-content--item {
    background: #f5f5f5;
    border-bottom: 1px dotted #cacaca;
}

// Editing
.column-header__editing {
    background: $accent;
    padding: 10px;
}

.import-grid--item__editing {
    border: 1px solid $accent;
}

.column-content--title__editing {
    background: $accent-light;
    border: 1px solid $accent;
    border-right: none;
    border-left: none;
}

// Errors
.import-grid--item__error {
    border: 1px solid $error;
}

.column-text-item__error {
    color: $error;
}

.column-content--title__error {
    background: $error-light;
    border: 1px solid $error;
    border-right: none;
    border-left: none;
}
    
