.marketplace-list {
  margin: 0 $grid-gutter-width/-2;

  .card {
    margin: 0 $grid-gutter-width/2 $line-height-computed * 2;
    width: 220px;
  }

  .card-title {
    margin-bottom: 0;
  }

  .card-body {
    font-size: $font-size-sm;
    min-height: 160px;
    /* IE 11 */
    text-align: center;

    p {
      margin: 0;
    }

    .new-badge {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      padding: 0px 10px;
      background-color: theme-color("secondary");
      color: #fff;
      border-radius: 1rem;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
      line-height: 1.5;
    }
  }
}

.marketplace-logo {
  margin: $line-height-computed/2 0 $line-height-computed;
  height: 64px;

  img {
    width: auto;
  }
}

.marketplace-header {
  margin: $line-height-computed 0 $line-height-computed;

  h2,
  .h2 {
    margin: 0 0 $line-height-computed/3;
  }

  .marketplace-logo {
    border: 1px solid #efefef;
    border-radius: 3px;
    height: auto;
    min-width: unset;
    margin: 0 $line-height-computed 0 0;
    min-width: 80px;
    padding: 10px;
    text-align: center;
  }

  .new-badge {
    display: inline-block;
    transform: translateY(-3px);
    padding: 1px 10px;
    background-color: theme-color("secondary");
    color: #fff;
    border-radius: 1rem;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    line-height: 1.4;
    margin-top: 0.5rem;

    @include media-breakpoint-up(sm) {
      margin-left: 5px;
      margin-top: 0;
    }
  }
}

.marketplace-features-intro {
  margin-bottom: $line-height-computed;
}

.marketplace-features {
  margin: 0 0 $line-height-computed * 2;

  li {
    margin: $line-height-computed/2 0;
  }
}

.marketplace-resources {
  li {
    & + li {
      margin-top: $line-height-computed/4;
    }
  }

  .pricing-table {
    hr {
      width: 95%;
      margin: 5px auto;
    }
  }
}

.marketplace-resources,
.marketplace-prop-container {
  background: $panel-default-heading-bg;
  padding: $panel-body-padding;

  h3,
  h4,
  .h3,
  .h4 {
    margin-top: 0;
  }

  ul {
    margin: 0;
  }

  p {
    margin: 0;

    & + p {
      margin-top: $line-height-computed;
    }
  }
}

.marketplace-prop-container {
  margin: $line-height-computed $grid-gutter-width/-2 $line-height-computed;

  @include media-breakpoint-up(sm) {
    margin-left: $grid-gutter-width * -1;
    margin-right: $grid-gutter-width * -1;
    padding: $grid-gutter-width $grid-gutter-width * 1.5;
  }
}

.marketplace-prop {
  & + .marketplace-investment {
    margin-top: $line-height-computed * 2;
  }
}

.marketplace-investment {
  font-size: 16px;
  font-style: italic;
  text-align: center;

  .btn {
    font-style: normal;
  }
}

.credit-select {
  margin: 0 auto 20px auto;
  text-align: left;
  width: 250px;
}
