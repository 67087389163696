md-autocomplete {

    .md-show-clear-button button md-icon {
        transform: translate3d(-50%,-50%,0) scale(.75);
    }

    .md-whiteframe-z1 {
        border: 1px solid $input-border;
        border-radius: $border-radius-base;
        box-shadow: none;
        //box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
    }
}

.md-autocomplete-suggestions-container {

    li {
        color: $text-muted;

        .highlight {
            color: $text-color;
            font-weight: $font-weight-bold;
        }

        .list-item-right {
            padding-left: $grid-gutter-width/3;

            i {
                padding-left: $grid-gutter-width/2;
            }
        }

    }
}

.md-virtual-repeat-container {

    &.md-autocomplete-suggestions-container {
        border-radius: $border-radius-base;
    }
}