// md-select-menu.md-default-theme md-content md-option[selected],
// md-select-menu md-content md-option[selected] {
//   color: $link-color;
// }

.md-select-backdrop {
  .modal-open & {
    z-index: 1099;
  }
}

.md-select-menu-container {
  z-index: 1100;
}

md-select {
  margin: 0;

  td & {
    margin: 0;
  }

  .ui-grid-cell-contents & {
    margin: -6px 0;
    max-width: 80px;
  }
}

md-select[disabled] {
  &.md-no-underline .md-select-value {
    background-image: none;
    margin-bottom: 0;
  }
}
