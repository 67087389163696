md-list {
  md-list-item {
    .md-button.md-icon-button {
      margin-right: -12px;
    }

    md-radio-button {
      align-self: center;
      margin: 6px 12px 6px 2px;
    }

    .md-list-item-inner {
      > md-checkbox {
        margin-right: 20px;
      }
    }
  }
}

md-list-item {
  &[disabled] {
    opacity: 0.8;
  }

  .help-block {
    margin: -10px 0 5px;
  }
}

.md-list-alt {
  md-list-item {
    background: $panel-default-heading-bg;
    border-radius: $border-radius-base;

    .md-list-item-inner {
      .md-list-item-text {
        padding: $grid-gutter-width/2 0;

        h3,
        .h3 {
          margin-bottom: $line-height-computed/4;
        }

        p {
          color: $text-color;
          font-weight: $font-weight-light;
          line-height: $line-height-computed;
        }
      }
    }

    & + md-list-item {
      margin-top: $line-height-computed/2;
    }
  }

  .md-list-item-options {
    margin-top: -10px;
    md-checkbox {
      margin-bottom: 0;
      padding-left: 5px;
    }
    p {
      line-height: $line-height-base;
      margin-top: -8px;
      margin-left: 30px;
      font-weight: 300;
    }
  }
}
