.badge-nav {
  background-color: #2db28a;
  color: #fff;
  font-size: $font-size-sm;
  margin-left: 5px;
  margin-top: 2px;
  min-width: auto;
  padding: 2px 5px;

  .dropdown-item & {
    position: relative;
    top: -1px;
  }
}

.badge-admin {
  background-color: #def5fc;
  border-radius: $border-radius;
  bottom: -1px;
  color: #0c5864;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: 600;
  margin-left: 10px;
  padding: 0 6px;
  position: relative;
  vertical-align: top;
}

.badge-chat {
  background-color: #efdcfc;
  border-radius: $border-radius;
  bottom: -1px;
  color: #7b1087;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: 600;
  margin-left: 10px;
  padding: 0 6px;
  position: relative;
  vertical-align: top;
}
