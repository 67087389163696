// Overview
.org-overview {
  background: #fafafa;
  margin: $line-height-computed/2 0 $line-height-computed * 1.5;
  padding: $line-height-computed/1.5 $grid-gutter-width/2 $line-height-computed;
}

// Services

.service {
  background-color: #fcfcfc;
  border: 1px solid lighten($input-border, 10%);
  border-radius: $panel-border-radius;
  margin: $line-height-computed $grid-gutter-width/2;
  min-width: 300px;
  padding: $line-height-computed * 2 $grid-gutter-width $line-height-computed/2;
  text-align: center;

  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }

  h3,
  .h3 {
    font-size: 20px;
    font-weight: 500;
    margin: $line-height-computed * 1.5 0 0;
  }

  md-switch {
    margin-left: 0;

    &.disabled {
      .md-container {
        opacity: 0.6;
      }
    }
  }
}

.service-banner {
  @extend .clearfix;
  background: lighten($state-warning-bg, 5%);
  display: table;
  font-size: $font-size-lg;
  font-weight: 300;
  margin: $line-height-computed/2 0 $line-height-computed;
  padding: $line-height-computed $grid-gutter-width/2;
  width: 100%;

  .icon {
    display: table-cell;
    padding: 0 $grid-gutter-width/1.5 0 $grid-gutter-width/4;
    vertical-align: middle;
    width: 33px;
  }

  .message {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
    width: auto;
  }
}

.service-details {
  margin-top: $line-height-computed/2;
  width: 100%;

  ul {
    padding-left: 0;
  }

  p {
    margin: 0 0 15px;

    .btn {
      margin-top: -2px;
    }
  }

  table.table {
    margin-bottom: $line-height-computed/2;
    text-align: left;
    width: 100%;

    tr {
      td {
        border: none;
        padding: 2px 0;
      }
    }
  }
}

// Billing

.form-account-billing {
  .form-group {
    margin-right: $grid-gutter-width;
  }

  .input-group {
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      width: 140px;
    }

    .form-control {
      font-size: 14px;
    }
  }

  @include media-breakpoint-up(sm) {
    .btn {
      margin-top: $line-height-computed/2;
    }
  }
}
