﻿$path: '../icons/fonts';

@font-face {
    font-family: 'icomoon';
    src:  url('#{$path}/icomoon.eot?nw82sj');
    src:  url('#{$path}/icomoon.eot?nw82sj#iefix') format('embedded-opentype'),
    url('#{$path}/icomoon.ttf?nw82sj') format('truetype'),
    url('#{$path}/icomoon.woff?nw82sj') format('woff'),
    url('#{$path}/icomoon.svg?nw82sj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"],
.tooltip-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-institution:before {
    content: "\e903";
}
.icon-users:before {
    content: "\e901";
}
.icon-hobsons:before {
    content: "\e900";
}
.icon-arrow-down:before {
    content: "\f104";
}
.icon-arrow-left:before {
    content: "\f107";
}
.icon-arrow-right:before {
    content: "\f10a";
}
.icon-arrow-up:before {
    content: "\f10d";
}
.icon-close:before {
    content: "\f404";
}
.icon-settings:before {
    content: "\e600";
}
.icon-archive:before {
    content: "\e604";
}
.icon-calendar:before {
    content: "\e605";
}
.icon-edit:before {
    content: "\e606";
}
.icon-mongoose:before {
    content: "\e607";
}
.icon-reply:before {
    content: "\e608";
}
.icon-students:before {
    content: "\e609";
}
.icon-add:before {
    content: "\f2c8";
}
.icon-lists:before {
    content: "\e602";
}
.icon-messages:before {
    content: "\e601";
}
.icon-paw:before {
    content: "\e603";
}
.icon-info-outline:before {
    content: "\e902";
}

img.emoji {
   height: 2em;
   width: 2em;
   margin: 0 .05em 0 .1em;
   vertical-align: -0.1em;

    h4 &, .h4 & {
        height: 1em;
        width: 1em;
    }
}