.card {
  border: 0;
  border-radius: $border-radius;
  box-shadow: $panel-box-shadow;

  &[role="link"] {
    cursor: pointer;

    &:hover {
      background: $table-hover-bg;
    }
  }

  .table th {
    border-top: 0;
  }

  .table {
    margin-bottom: 0;
  }

  &.card-sm {
    box-shadow: none;
    border: 1px solid $panel-inner-border;
    margin: $line-height-computed 0;

    .card-header {
      font-size: $h6-font-size;
      padding: 5px 10px;
    }

    .card-footer {
      background: #fff;
      padding: 5px 10px;
    }

    //.panel-heading {
    //    font-weight: 300;
    //    padding: $modal-header-padding-y $modal-header-padding-x;
    //}
    //
    //.panel-body {
    //    padding: $table-cell-padding-sm;
    //}

    .empty-message {
      margin: $line-height-computed 0;
    }
  }
}

.card-header {
  font-size: $h5-font-size;

  .card-title {
    font-size: $h5-font-size;
    margin: 0;
  }
}

.card-body {
  padding: $panel-body-padding;
  position: relative;
}

.card-avatar {
  min-width: 80px;
  padding: 0 $line-height-computed 0 0;
}

.card-title {
  //font-size: $h5-font-size;
}

.flag {
  background: $blue;
  border-radius: 4px;
  color: #fff;
  font-size: $font-size-xs;
  font-weight: 300;
  letter-spacing: 0.5px;
  padding: 1px 8px;
  text-transform: uppercase;

  .card & {
    border-radius: 0 0 4px 0;
    left: 0;
    position: absolute;
    top: 0;
  }

  .subpage-toolbar-title & {
    margin-left: $grid-gutter-width/2;
  }
}

.flag-disabled {
  background-color: $gray-light;
}

.card-footer {
  background: $panel-default-heading-bg;
  border-radius: 0 0 $border-radius-base $border-radius-base;
  border-top: 1px solid $panel-inner-border;
  padding: $panel-heading-padding;

  p {
    color: $text-muted;
    font-weight: 300;
    margin: 0;
  }

  .card-title {
    & + p {
      font-size: $font-size-sm;
      margin-top: $line-height-computed/6;
    }
  }
}

.card-footer-link {
  cursor: pointer;
  position: relative;

  &:after {
    color: #d3d3d5;
    content: "";
    display: inline-block;
    font: normal normal normal 18px/1 FontAwesome;
    margin-top: -7px;
    position: absolute;
    right: 15px;
    text-rendering: auto;
    top: 50%;
  }
}
