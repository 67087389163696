.navbar-dark {
  background-color: #33bb95 !important;

  .navbar-nav {
    .nav-link {
      color: #fff;
      position: relative;

      @include media-breakpoint-up(lg) {
        color: rgba(255, 255, 255, 0.8);
        padding: 5px 15px;

        &:hover {
          color: #fff;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .profile-menu {
        .nav-link {
          padding: 0 10px;

          &.dropdown-toggle {
            &::after {
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-dark {
    .navbar-nav {
      .nav-link {
        color: rgba(255, 255, 255, 0.8);
        padding: 5px 15px;

        &:hover {
          color: #fff;
        }
      }

      .profile-menu {
        .nav-link {
          padding: 0 10px;

          &.dropdown-toggle {
            &::after {
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }
}

.navbar {
  min-height: $navbar-height;

  .navbar-brand {
    height: 34px;
    padding: 2px 0;
    @include media-breakpoint-up(lg) {
      width: 137px;
      overflow: hidden;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &.hide-navbar {
      @include media-breakpoint-up(lg) {
        width: 0;
        margin-right: 0;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
    }
  }

  .icon-badge {
    background-color: theme-color("danger");
    border-radius: 50%;
    display: inline-block;
    font-size: 30px;
    position: absolute;
    height: 10px;
    width: 10px;
    left: 24px;
    top: 3px;

    @include media-breakpoint-down(md) {
      height: 7px;
      width: 7px;
      left: 20px;
      top: 2px;
    }
  }
}

.navbar-header {
  position: relative;
  z-index: 1;
}

.navbar-icon-link {
  color: #fff;

  i {
    font-size: 20px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    position: relative;
  }

  .support-link {
    cursor: pointer;

    i {
      font-size: 22px;
    }
  }
}

.main-nav-toggle {
  margin-left: -14px;
  margin-right: 5px;

  .nav-link {
    padding-top: 1px;
    padding-bottom: 0;
  }
}

.navbar-icon-number {
  color: theme-color("danger");
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  position: absolute;
  right: 28px;
  top: 6px;
}

.profile-menu {
  .dropdown-toggle {
  }

  .dropdown-menu {
    min-width: 180px;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-nav .open .dropdown-menu {
    background-color: $nav-button;
  }
}
