﻿/* Loading */
#app-start {
    color: transparentize(#fff, .75);
    font-size: 5em;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 40%;
    width: 100%;
    z-index: 0;

    small {
        display: none;
        font-size: 24px;
    }
}

#loader,
#loading {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1000;
    background-color:rgba(255,255,255,.7);
    opacity: .5;
 }

.loader-spinner {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -27px;
    margin-top: -27px;
    display: block;
    height: auto;
    width: 38px;
}

.growl-container.growl-fixed.top-right {
  top: 60px;
  right: 15px;
}