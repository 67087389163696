$row-margin: $line-height-computed/1.5;

.criteria-builder {
    margin: $line-height-computed 0 $line-height-computed*2;
    max-width: 100%;
    overflow: auto;

    .form-inline {
        background-color: #fafafa;
        padding: $line-height-computed/2 $grid-gutter-width/2;
    }

    .form-control {
        max-width: 26%;
    }
}

.criteria-conjunction {
    margin: $line-height-computed/2 0;

    select {
        text-transform: uppercase;
    }
}

.criteria-conjunction-label {
    color: $text-muted;
    display: inline-block;
    font-size: $font-size-sm;
    text-align: center;
    text-transform: uppercase;
    width: 2.5em;
}

.criteria-row {
    margin: $row-margin 0;

    .form-control {
        margin-left: 4px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.criteria-add {
    position: relative;

    .btn {
        margin-left: -4px;
        text-decoration: none;

        i {
            color: $brand-alt;
        }
    }
}

.criteria-results-details {
    margin: $line-height-computed 0 $line-height-computed/2;
}

.criteria-results-count {
    font-weight: 600;
}

.criteria-preview-container {
    max-height: 230px;
    opacity: 1;
    overflow: auto;

    &.ng-hide-add,
    &.ng-hide-remove {
        overflow: hidden;
        @include transition(all .2s ease-in-out);
    }

    &.ng-hide {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }
}

.criteria-preview {
    margin-top: $line-height-computed/2;

    td & {
        font-size: $font-size-sm;
    }

    .popover-body & {
        margin-top: 0;
        padding: $line-height-computed/2 $grid-gutter-width/2;
    }

    .field-name {
        margin: 0 4px;
    }

    .field-comparison {
        color: $text-muted;
        margin: 0 4px 0 0;
    }
}

.segment-alt-action {
    float: right;
    margin: $line-height-computed/2*-1 0 $line-height-computed*-1;
}

.selectable-user-list {
    margin-left: 20px;

    .scrollable-ui {
        margin-top: $line-height-computed;
        max-height: 258px;
    }

    label {
        font-weight: normal;
        margin-bottom: 0;
    }

    ul {
        li {
            margin-bottom: $line-height-computed/3;
        }
    }
}

.staff-assignment-filter {
    margin: $line-height-computed 0 0;

    label {
        font-weight: 400;
    }
}