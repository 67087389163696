﻿.profile-sidebar {
  border-left: 1px solid $gray-lighter;
  overflow: hidden;

  md-content {
    height: 100%;
  }

  .activity-fields {
    background: $light;
    margin: 16px -16px 0;
    padding: 4px 16px;
  }

  .table {
    > tbody > tr > td {
      font-size: $font-size-sm;
      line-height: 1.3;
      padding-bottom: $line-height-computed/2;
      padding-top: 0;
    }
  }
}

.profile-sidebar-optout-row {
  border-bottom: 1px solid $gray-lighter;
  border-bottom: 1px solid transparentize($input-border, 0.65);
  margin: -8px -8px 8px;
  padding: 16px 16px;
}

.profile-sidebar-optout-row,
.student-modal-optout-row {
  .tooltip-icon {
    bottom: 0;
  }
}

.student-modal-optout-row {
  width: 50%;
}

.conversation-header {
  outline: none;
}

// Opt-Out & Block/Mute

.block-field,
.optout-field {
  min-width: 110px;

  md-switch {
    margin: 0;
    text-transform: uppercase;

    .md-label {
      margin-top: 1px;
    }
  }

  .dropdown-menu {
    width: 160px;
  }
}

.optout-dropdown {
  margin-left: 0;

  &.open .dropdown-toggle {
    background-color: #fff;
    border-color: #ccc;

    &:hover,
    &:focus {
      background-color: #fff;
      border-color: #ccc;
    }
  }

  li {
    padding: 15px;

    & + li {
      border-top: 1px solid #e5e5e5;
    }
  }

  .switch-header {
    margin-bottom: 3px;
  }
}

/* Conversation */
.message-wrapper {
  &:hover,
  &.active {
    .message-action-icon,
    .message-action-add {
      @include transition(opacity 200ms ease-in);
      opacity: 1;
    }
  }
}

.message-preview {
  .message {
    float: none;
    width: 100%;
  }

  &.media-with-text {
    .message-media {
      margin-top: $line-height-computed/2;
    }
  }

  .message-content {
    border-radius: 12px;
    margin-top: $line-height-computed/2;

    .popover-content &,
    .tooltip-inner & {
      background: none;
      border-radius: 0;
      margin: $line-height-computed/2 * -1-1 $grid-gutter-width/2 * -1-1;
      padding: $line-height-computed/2 $grid-gutter-width/2;
    }

    &:after {
      display: none;
    }
  }
}

.auto-reply-label {
  font-size: 13px;
  font-style: italic;
  padding-left: 4px;
}

.auto-reply-value {
  background-color: $gray-lightest;
  border-radius: 4px;
  font-style: normal;
  margin: 0 2px;
  padding: 0 4px;
  text-transform: uppercase;
}

.message {
  padding: $line-height-computed/2 20px;
  width: 90%;

  @include media-breakpoint-up(sm) {
    width: 70%;

    .modal-body & {
      width: 90%;
    }
  }

  &.outgoing {
    float: right;
  }

  &.consecutive {
    @include transition(padding 200ms ease-out);

    padding-bottom: 0;

    .message-source {
      @include transition(max-height 200ms ease-out);
      max-height: 0;
      overflow: hidden;
    }
  }

  &.media-with-text {
    .message-media {
      margin-top: $line-height-computed/2;
    }
  }

  &.pending {
    .message-content {
      opacity: 0.4;
    }

    .message-source {
      font-style: italic;
      font-weight: 400;
    }
  }
}

.message-content {
  @include transition(opacity 200ms ease-out);

  background: #f2f2f3;
  border-radius: 12px 12px 12px 0;
  padding: $line-height-computed;
  position: relative;

  .emoji {
    margin-bottom: -5px;
    height: 24px;
    width: auto;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent #f0f0f0 transparent;
    position: absolute;
    left: -10px;
    bottom: 0;
  }

  .outgoing & {
    background: $nav_button;
    border: none;
    border-radius: 12px 12px 0 12px;
    color: #fff;
    text-align: right;

    &:after {
      border-color: transparent transparent transparent $nav_button;
      border-width: 10px 0 0 10px;
      left: auto;
      right: -10px;
    }
  }

  .auto-reply-preview & {
    padding: {
      bottom: $line-height-computed/2;
      top: $line-height-computed/2;
    }
  }
}

.message-content-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.message-media {
  > a {
    display: inline-block;
    margin-right: $grid-gutter-width/2;

    .outgoing & {
      margin-right: 0;
    }
  }
}

.message-media-thumbnail {
  max-height: 120px;
  max-width: 120px;
}

.message-attachment {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
  line-height: 30px;
  padding: 1px 8px 3px;

  .outgoing & {
    background: rgba(255, 255, 255, 0.2);

    a {
      &:hover {
        color: lighten($accent, 4%);
      }
    }
  }

  i {
    display: inline-block;
    font-size: 24px;
    vertical-align: middle;
  }

  a {
    font-size: 12px;
    vertical-align: middle;

    &:hover {
      color: darken($accent, 4%);
    }
  }
}

.message-info {
  margin-top: 5px;
}

.message-source,
.message-campaign {
  color: $text-muted;
  font-size: $font-size-sm;
  font-weight: 300;
  max-height: $line-height-computed;
}

.message-source {
  @include media-breakpoint-up(xs) {
    float: left;

    .outgoing & {
      float: right;
      clear: both;
    }
  }
}

.message-campaign {
  margin-top: 2px;

  .tag {
    background-color: $blue;
    border-radius: 4px;
    color: #fff;
    margin-right: 2px;
    padding: 1px 5px;
  }

  .message-action-icon {
    margin: -6px -17px -3px -2px;
  }

  @include media-breakpoint-up(xs) {
    float: right;
    margin: 0 5px;

    .outgoing & {
      float: left;
    }
  }
}

.message-action-add {
  float: right;
  margin-right: -26px;
  margin-top: 2px;

  border: none;
  border-radius: 2px;
  color: #888;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  opacity: 0.25;

  .outgoing & {
    float: left;
    margin: {
      left: -26px;
      right: 0;
    }
  }
}

.message-action-icon {
  @extend .btn-link;
  color: #888;
  font-size: 18px;
  opacity: 0;
  padding: 0 4px;
  position: relative;
  vertical-align: middle;

  border: none;
  border-radius: 2px;
  font-weight: 600;
  line-height: 15px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:active {
    outline: 0;
  }
}

.delivery-error {
  color: red;
  cursor: default;
  font-size: $font-size-sm;

  &:hover {
    color: red;
  }
}

.more-info {
  color: $accent;
  cursor: pointer;
  font-size: 10px;
  font-weight: normal;
  text-decoration: underline;
  text-transform: uppercase;
}

.more-info .details {
  text-decoration: none;
  color: #aaa;
  position: absolute;
  left: 0;
  top: 19px;
  font-size: 10px;
  line-height: 12px;
}

.outgoing .more-info {
  display: none;
}

.compose-input {
  background-color: #fff;
  border-left: 1px solid rgba(204, 204, 204, 0.35);
  border-top: 2px solid #eee;
  min-height: 64px;
  position: relative;
  width: 100%;

  .compose-toolbar {
    border-width: 1px 0 0;
  }

  &.hasMediaOverlay {
    min-height: 125px;
  }

  .message-progress {
    margin: -2px 0 0;

    md-progress-linear {
      height: 2px;

      .md-container {
        height: 2px;

        .md-bar {
          height: 2px;
        }
      }
    }
  }
}

.compose-input-form {
  padding: $line-height-computed/2 $grid-gutter-width/2;

  .group-conversation & {
    border-left: 5px solid $accent;
  }
}

.compose-toolbar {
  background-color: $light;
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
  border: 1px solid $input-border;
  border-top: 1px solid transparentize($input-border, 0.65);
  padding: $line-height-computed/3 $grid-gutter-width/2 $line-height-computed/3 $grid-gutter-width/4;

  .btn {
    background-color: $panel-default-heading-bg;
    border: none;
    border-radius: 2px;
    color: #555;
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: $grid-gutter-width/6;

    &:hover,
    &:focus,
    &.focus {
      background-color: darken($panel-default-heading-bg, 10%);
    }
  }

  .text-danger {
    font-style: italic;
  }

  md-select {
    font-size: $font-size-sm;
    margin: -8px 0 -4px;
  }

  .btn-icon {
    border: none;

    i {
      bottom: -2px;
      font-size: 18px;
      margin: -2px 0;
      position: relative;
    }
  }

  .btn-media {
    i {
      opacity: 0.75;
    }
  }
}

.file-drop-area {
  background: transparentize($gray-light-bg, 0.2);
  border: 2px dashed #aeaeae;
  border-radius: 6px;
  margin-bottom: $line-height-computed;
  padding: $line-height-computed * 3 $grid-gutter-width;
  position: relative;
  text-align: center;

  h4,
  .h4 {
    font-weight: 400;
    margin: 0 0 $line-height-computed/1.5;

    small {
      font-size: $font-size-base;
      margin: $line-height-computed/1.5 0 $line-height-computed * -0.25;

      &.text-muted {
        font-size: $font-size-sm;
      }
    }
  }

  small {
    color: $text-muted;
    display: block;
  }

  &.dragover {
    background: transparentize($gray-lightest, 0.1);
    border-color: $accent-alt;

    .file-drop-area-icon {
      transform: translateY(-5px);
    }
  }

  &.active {
    border-style: solid;
  }
}

.file-drop-area-icon {
  margin-bottom: $line-height-computed;
  transition: transform 250ms ease-out;
}

.file-drop-area-error {
  margin: $line-height-computed * 1 0 $line-height-computed * -1;
}

.file-drop-file-name {
  font-weight: $font-weight-light;
  margin: $line-height-computed $grid-gutter-width $line-height-computed/2;
}

.media-picker-overlay {
  border-radius: $border-radius-base;
  margin-bottom: 0;
  padding: $line-height-computed/2 $grid-gutter-width/2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;

  h4,
  .h4 {
    margin-bottom: $line-height-computed/4;
  }

  small {
    margin-bottom: $line-height-computed/1.5;
  }

  .file-drop-area-error {
    background: theme-color-level("danger", $alert-bg-level);
    border: 1px solid theme-color-level("danger", $alert-border-level);
    border-radius: $border-radius-base;
    color: theme-color-level("danger", $alert-color-level);
    margin-top: -20px;
    max-width: 100%;
    padding: $line-height-computed/2 $grid-gutter-width/2;
    position: absolute;
    top: 50%;
    width: 400px;
  }
}

.media-picker-preview {
  color: $text-muted;
  font-size: 11px;
  font-weight: 100;
  margin-right: $grid-gutter-width/2;
  max-width: 100px;
  position: relative;

  img,
  video {
    max-height: 80px;
    max-width: 100%;
    //width: 100px;
  }
}

.media-picker-progress {
  min-width: 90px;
}

.media-picker-preview-media {
  background: #fafafa;
  margin-bottom: $line-height-computed/3;
  text-align: center;
}

.media-picker-preview-info {
  .btn-close {
    position: relative;
    top: auto;
    right: auto;
  }
}

.compose-textarea {
  border: none;
  font-size: 16px;
  font-weight: 300;
  margin-right: $grid-gutter-width/2;
  outline: none;
  padding: $input-padding-y $input-padding-x;
  resize: none;
}

.sb-collapsed .message-input {
  margin-left: 55px;
}

.conversation-modal {
  height: 100%;
  max-height: 400px;
  overflow: auto;
}

.emojiPicker {
  padding: 5px;

  span {
    border-radius: 3px;
    padding: 4px;
    margin: 1px;

    @include transition(background 200ms ease-in-out);

    &:hover {
      background-color: $gray-lighter;
      cursor: pointer;
    }
  }

  .emoji {
    height: 18px;
    width: 18px;
  }
}

.emojiPicker-group {
  & + & {
    border-top: 1px solid #ededed;
    margin-top: 8px;
    padding-top: 8px;
  }
}

.mms-image {
  & + & {
    margin-top: $line-height-computed * 2;
  }

  img,
  video {
    margin-bottom: $line-height-computed/2;
  }
}

.lockTimeout-modal {
  text-align: center;

  .modal-header {
    h4,
    .h4 {
      font-weight: 400;
    }

    .emoji {
      height: 28px;
      margin-bottom: -5px;
      margin-right: 3px;
      width: auto;
    }
  }

  .modal-body {
    margin-top: 10px;
  }

  .modal-footer {
    text-align: center;
  }

  .timer {
    font-size: $h3-font-size;
    font-weight: 100;
    margin: 0;
  }
}

.reply-overlay-container {
  position: relative;

  .reply-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #f2f2f3;
    padding: 30px 35px;
    z-index: 3;
    button {
      margin-right: 10px;
      i {
        padding-right: 10px;
      }
    }
  }

  .message-locked {
    font-size: 13px;
    line-height: 1.4;
    font-style: italic;
    margin-bottom: 3px;
  }

  .locked-status {
    color: $text-muted;
  }
}

.sms-ai-button-container {
  display: flex;
  align-items: center;
  border-right: 2px solid $gray-lighter;
  padding-right: 0.5rem;
  margin-right: 0.5rem;

  button {
    height: 24px;
    width: 24px;
    padding: 0;
    border: none;
    border-radius: 2px;
    background: inherit;
    background-image: url("/images/icon-stars.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    transition: background-image 200ms ease;

    &:hover {
      background-image: url("/images/icon-stars-solid-light.svg");
    }

    &.active {
      background-image: url("/images/icon-stars-solid.svg");
    }

    img {
      transform: translateY(-2px);
    }
  }
}
