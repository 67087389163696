.legacy-dashboard-subheader {
  font-weight: 300;
  color: #212529;
  font-size: 20px;
}

.message-activity {
  .card-title {
    margin: 0;
    min-height: 46px;
    padding: $line-height-computed/4 $line-height-computed/4 $line-height-computed;
  }

  .chart-legend-wrapper {
    color: $text-muted;
    font-size: 11px;
    padding-top: 5px;
  }
  .chart-legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: $grid-gutter-width/2;

    svg {
      margin-left: 2px;
    }
  }
  .chart-wrapper {
    position: relative;
    min-height: 220px;
    height: 33vh;
    max-height: 320px;
  }
}
