.contact-modal {
  .modal-header {
    border-bottom: 0;
    margin-bottom: -15px;
  }

  .contact-actions {
    margin: 0 0 -5px;
  }

  .cadence-fields {
    margin: -5px 0 20px;
  }

  .mobile {
    font-size: 16px;
  }

  .activity-fields {
    background: $light;
    margin: 0 -30px -10px;
    padding: 15px 30px;

    h4,
    .h4 {
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      margin: 5px 0 8px;
      text-transform: uppercase;

      img {
        margin-right: 3px;
        vertical-align: top;
      }
    }
  }

  .custom-fields {
    margin: 25px -10px 5px;

    td {
      padding: 5px 10px;
    }
  }
}

.contact-activity-label {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}
