.list-template {
    .table-responsive {
        overflow: visible;
    }
    table {
        .item {
            border: 1px solid #cbcbcb;
        }

        .actions {
            width: 20%;
            a{
                color: #fff;
                background: rgb(35, 188, 152);
                padding: 5px 10px;
                margin-right: 5px;
            }
        }
    }

    tr {
        td:first-child {
            //width: 94%;
        }
    }
}
.admin-usage{
    table{
        th{
            text-align: left;
            padding: 7px 13px;
            background: $accent;
            color: #fff;
            border-left: 1px solid #cbcbcb;
        }    
    }
}
