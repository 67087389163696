//== Variable Overrides

@import "common/variables";

//== Bootstrap 4
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
//@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
//@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
//@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/bootstrap/scss/toasts";

//== Vendor
@import "vendor/angular-growl-v2";
@import "vendor/angular-ui-switch";
@import "font-awesome";

//== Custom
@import "common/global";
@import "common/animations";

@import "components/alerts";
@import "components/app-reload";
@import "components/avatar";
@import "components/badges";
@import "components/buttons";
@import "components/button-groups";
@import "components/card";
@import "components/code";
@import "components/datepicker";
@import "components/dropdowns";
@import "components/elevate-banner";
@import "components/forms";
@import "components/growl";
@import "components/import-file";
//@import "components/input-groups";
@import "components/modals";
@import "components/navbar";
@import "components/navs";
@import "components/notifications";
@import "components/panels";
@import "components/popover";
@import "components/progress-bar";
@import "components/progress-steps";
@import "components/quick-tip-button";
@import "components/reporting";
@import "components/search";
@import "components/segments";
@import "components/tables";
@import "components/tags";
@import "components/tags-input";
@import "components/timepicker";
@import "components/tooltip";
@import "components/type";
@import "components/dashboard/messages";

@import "utilities/sizing";

@import "layouts/page";
@import "layouts/account";
@import "layouts/accounts";
@import "layouts/contacts";
@import "layouts/dashboard";
@import "layouts/flex";
@import "layouts/marketplace";
@import "layouts/inbox";
@import "layouts/inbox-layout";
@import "layouts/new-user";
@import "layouts/organization";
@import "layouts/public";
@import "layouts/usage";
@import "layouts/out-of-office";

@import "core/icons";
@import "core/loading";

// Material
@import "material/global";
@import "material/md-autocomplete";
@import "material/md-checkbox";
@import "material/md-input";
@import "material/md-list";
@import "material/md-menu";
@import "material/md-nav-bar";
@import "material/md-progress-linear";
@import "material/md-radio";
@import "material/md-select";
@import "material/md-sidenav";
@import "material/md-switch";
@import "material/md-tabs";
@import "material/md-toolbar";

// Messages
@import "messages/compose";
@import "messages/conversation";

// Templates
@import "templates/new-template";
@import "templates/list-template";

@import "lists/import-spreadsheet";

// Has conflicting mixins so make it last
@import "components/ui-grid/ui-grid";

// Temp placement of page styles
@import "pages/login.scss";
