﻿//== Bootstrap 4

$theme-colors: (
  "primary": #233c48,
  "secondary": #da3920,
  "accent": #33bb95,
  "blue": #396fb0,
  "default": #ccc,
);

$gray-100: #f9fafb;
$light: $gray-100;

//== Custom

// Layout
$sidebar-width: 230px;
$footer-nav-box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.04);

// Colors
$main: #233c48;
$accent: #33bb95;
$accent-light: #d1ede8;
$accent-alt: #4abce2;
$accent-alt-a11y: #006ba8;
$error: #fc6965;
$error-light: #feedec;
$background: $main;
$nav-background: #1a2f3a;
$nav-button: $main;
$button-primary: $main;
$button-accent: $accent;
$button-disabled: #adb5bd;
$avatar-black: #243b4a;

// Messaging
$message-emoji-size: 2rem;

//== Bootstrap Overrides

// Colors
$gray: #555;
$gray-light: #838d96;
$gray-lighter: #e5e5e5;
$gray-lightest: #f5f5f5;
$gray-light-bg: #fafafa;
$gray-subtitle: #6c757d;
$text-muted: #6c757d;
$dark-gray-subtitle: #212529;
$gray-pane-border: #e2e2e2;
$chat-pane-border: #74929a;

$brand-alt: $accent;

$blue: #396fb0;
$blue-dark: #233c48;
$purple-light: #83608d;
$purple-chat: #93488c;
$red-danger: #d83a22;

$sidebar-active-bg: #2a2c34;

// Scaffolding
$line-height-computed: 20px;

$text-color: #555;
$link-color: #006ba8;

// Type
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-alt: "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base: 0.875rem;
$font-size-sm: 0.8rem;
$small-font-size: 85%;
$font-size-xs-ratio: 0.75;
$font-size-xs: $font-size-base * $font-size-xs-ratio;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$h1-font-size: $font-size-base * 2.15 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.15 !default;
$h6-font-size: $font-size-base !default;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: $font-weight-light;

// Code
$code-color: $text-color;
$kbd-color: $text-color;
$kbd-bg: $gray-lighter;

// Components
$global-border-radius: 3px; // use -small, -base, or -large; this variable is for testing
$border-radius-small: $global-border-radius;
$border-radius-base: $global-border-radius;
$border-radius-large: $global-border-radius;

// Alerts
$alert-success-bg: #dbf6d3;
$alert-success-text: #569745;
$alert-success-border: darken(adjust-hue($alert-success-bg, -10), 5%);

// Badges
$badge-border-radius: 2px;
$badge-font-weight: $font-weight-normal;

// Buttons
$btn-default-border: #dadada;

// States
$state-warning-bg: #fdfdbb;
$state-warning-border: #ffd551;

//$state-danger-text: #cc0000;

// Icons
$icon-font-path: "icons/fonts/";
$fa-font-path: "icons/fonts/";

// Cards
$card-cap-bg: #f4f5f6;

// Dropdown
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 10px;
$dropdown-item-padding-x: 15px;
//$dropdown-link-hover-color: #fff;
//$dropdown-link-hover-bg: $brand-alt;
$dropdown-link-active-bg: $gray-lighter;
$dropdown-link-active-color: $text-color;
//$dropdown-divider-bg: #e6e6e6;

// Forms
$input-border: #ccc;
$input-bg-disabled: #f9f9f9;
$input-focus-border-color: $accent-alt-a11y;
$input-btn-focus-width: 0px;
$input-btn-focus-color: $accent-alt-a11y;

// Media
$screen-md: 960px;
$screen-lg: 1200px;

// Modals
$modal-md: 600px;
$modal-inner-padding: 10px 30px;
$modal-header-padding-y: 15px;
$modal-header-padding-x: 30px;

$uibModal-title-padding: 20px 30px 0;
$uibModal-inner-padding: 10px 30px;

//
$navbar-height: 50px;
//$navbar-inverse-link-color: rgba(255, 255, 255, 0.8);
//$navbar-inverse-bg: $brand-alt;
$navbar-inverse-link-active-bg: rgba(255, 255, 255, 0.3);

//$navbar-inverse-toggle-hover-bg: transparent;
//$navbar-inverse-toggle-border-color: transparent;

// Navs
$nav-tabs-default-active-link-bg: $gray-light-bg;

// Pills
$nav-pills-border-radius: 4px;
//$nav-pills-active-link-hover-bg:            $component-active-bg !default;
//$nav-pills-active-link-hover-color:         $component-active-color !default;

// Panels
$panel-bg: #fcfcfd;
$panel-body-padding: 20px;
$panel-heading-padding: 15px 20px;
$panel-footer-padding: $panel-heading-padding !default;
$panel-border-radius: $border-radius-base;
$panel-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

$panel-inner-border: #e4e4e5;
//$panel-footer-bg:             #f5f5f5 !default;

//$panel-default-text:          $gray-dark !default;
$panel-default-border: $panel-inner-border;
$panel-default-heading-bg: $gray-light-bg;

// Popover
$popover-max-width: 268px;
$popover-arrow-width: 12px;
$popover-border-color: #ccc;
$popover-header-bg: #f2f2f3;

// Tables
$table-head-bg: $card-cap-bg;
$table-hover-bg: $table-head-bg;
$table-border-color: $panel-inner-border;
$table-cell-padding: 20px 15px;
$table-cell-padding-sm: 10px 15px;
$table-accent-bg: $light;
$table-striped-order: even;

// Tooltip
$tooltip-max-width: 240px;
$tooltip-bg: #666;
$tooltip-opacity: 1;

// Message Content
$message-content-bg: #f2f2f3;
$outgoing-message-content-bg: #e1f4ef;
$outgoing-note-content-bg: #fffac2;
