/* New Message */
.three-buttons {
  margin: 0;
  text-align: center;
  width: 100%;
}

.three-buttons .btn {
  float: none;
  margin: 0;
}

.calendar-show .dropdown-menu {
  top: auto !important;
  bottom: auto !important;
  padding: 0 0 10px 0 !important;
}

.calendar-show table tbody td,
.calendar-show table thead th {
  padding: 0 6px 0 0;
}

.calendar-show table thead .text-center {
  padding: 5px 10px;
}
.helper-trigger {
  color: #666;
  cursor: pointer;
  padding: 5px 5px 9px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-group {
  position: relative;
}

.helper-content {
  background: #666;
  color: #fff;
  max-height: 0;
  margin-bottom: 0;
  //  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}
.helper-content.active:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #666 transparent;
  position: absolute;
  top: -7px;
  right: 4px;
}
.helper-content.active {
  max-height: 800px;
  //opacity: 1;
  overflow: visible;
  margin-bottom: 10px;
  clear: both;
}
.helper-content p {
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  padding: 10px 20px 0px 10px;
}

.helper-content p:last-child {
  margin: 0 0 5px;
  padding-bottom: 10px;
}

.helper-content + input,
.helper-content + select,
.helper-content + textarea {
  position: relative;
  z-index: 3;
}

// Show Select List Checkbox
.button-select-list {
}

.message-body-container {
  //border: 1px solid $input-border;
  border-radius: $border-radius-base;
  margin-bottom: 10px;
  position: relative;
}

.message-input {
  &.hasMedia {
    border: 1px solid $input-border;
    border-radius: $input-border-radius $input-border-radius 0 0;
    border-width: 1px 1px 0;
    padding: $line-height-computed/2 $grid-gutter-width/2;

    textarea {
      border-color: transparent;
      padding: 5px 10px;

      &:focus {
        border-color: $input-focus-border-color;
        border-radius: $input-border-radius;
        border-width: 1px;
        //margin: 0 -5px;
        padding: 5px 10px;
      }
    }
  }
}

.message-body {
  border-radius: $input-border-radius;
  border-width: 1px;
  font-size: $font-size-base;
  height: 100%;
  min-height: 80px;
  resize: none;

  &.has-toolbar {
    border-radius: $input-border-radius $input-border-radius 0 0;
    border-width: 1px 1px 0;
  }

  &:focus {
    border-width: 1px;
  }
}

.modal-header {
  &.hasBack {
    h3,
    .h3 {
      margin-left: -18px;
    }
  }
  &.fromAccount {
    h3,
    .h3 {
      font-size: 20px;
      margin-bottom: $line-height-computed/4;
      margin-top: $line-height-computed/4;
    }
  }
}

.modal-tab-container {
  background-color: $gray-lighter;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;

  .modal-header {
    border-bottom: 0;
  }

  .nav-tabs {
    border-color: $gray-lighter;
    margin: 0 $modal-header-padding-x;

    li {
      a,
      a:hover {
        background-color: lighten($gray-lighter, 3%);
        border: 0;
        border-bottom: 2px solid $gray-lighter;
        color: $text-muted;

        & > span {
          display: none;
        }
      }

      &.active a {
        background-color: #fff;
        border-bottom: 2px solid #fff;
        color: $text-color;
        font-weight: 600;

        &:focus {
          border-width: 0 0 2px;
        }

        & > span {
          display: inline-block;
        }

        & > i {
          display: none;
        }
      }

      & i.icon-hobsons {
        color: $gray;
        float: left;
        font-size: 20px;
        margin-left: -6px;
        margin-right: 2px;
      }
    }
  }
}

.compose-options {
  margin: 22px 0 10px;

  md-checkbox {
    margin: 6px 0;
  }

  md-switch {
    margin: 4px 0;
  }
}

.message-progress {
  md-progress-linear {
    ._md-container {
      background-color: $gray-lighter;
      height: 3px;
    }

    ._md-bar {
      background-color: $brand-alt;
      height: 3px;
    }
  }
}

.message-length-warning {
  font-size: 12px;
  margin-bottom: 3px;

  .max-reached & {
    color: theme-color(danger);
  }
}

.new-message {
  .helper-trigger {
    position: relative;
    float: right;
    padding: 20px;
  }

  .text-description {
    color: #d65454;
    margin-right: 20px;
  }

  .checkbox {
    label {
      font-weight: bold;
    }
  }
}

.inserted-field {
  margin: 0 5px;
  line-height: 31px;
  padding: 5px 10px;

  background: $accent;
  color: #ffffff;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;

  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .remove-button {
    margin: 0 0 0 5px;
    padding: 0;
    border: none;
    background: 0 0;
    cursor: pointer;
    vertical-align: middle;
    font: 700 16px Arial, sans-serif;
    color: #ffffff;
  }
}

.action-block {
  .btn,
  button {
    vertical-align: top;
    margin-bottom: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.autocomplete-add-button {
  margin: $line-height-computed/4 0 $line-height-computed/-4 0;

  .tags & {
    float: left;
    margin: 2px 4px;
    padding: 3px $input-padding-x-sm;
  }
}

.recipient-picker {
  .activity-dots {
    margin: 2px 0 0 2px;
    position: absolute;
  }
}

.additional-buttons {
  margin-left: 6px;
}

.additional-button {
  font-size: $font-size-sm;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.compose-campaign {
  @include clearfix;

  @include media-breakpoint-up(xs) {
    md-switch {
      float: left;
    }

    .campaign-selector {
      float: right;
      margin: -1px 0;

      tags-input {
        width: 360px;
      }
    }
  }
}

.campaign-selector {
  tags-input {
    .autocomplete {
      .suggestion-list {
        max-height: 102px;
      }
    }
  }

  .error-list {
    margin: $line-height-computed/4 0 0;
  }
}

@media (max-width: 640px) {
  .action-block {
    text-align: center;
  }
}

// Step 0
.compose-sender {
  padding: $line-height-computed 0 $line-height-computed/2;

  md-list-item div.md-button:first-child .md-list-item-inner {
    width: 90%;
  }

  md-list-item[disabled] md-icon {
    display: none;
  }

  .md-secondary-container md-icon.chevron {
    color: $gray-light;
    font-size: $font-size-lg;
    height: auto;
    width: auto;
  }
}

// Step 2
.compose-preview {
  margin: $line-height-computed/3 0 $line-height-computed;

  .message {
    padding: 0 $grid-gutter-width/2;

    .modal-body & {
      width: 100%;
    }
  }
}

.compose-audit-list {
  font-size: $font-size-sm;
  list-style: none;
  margin-top: $line-height-computed/3 * -1;
  padding: 0;

  & > li {
    border-top: 1px solid $hr-border-color;
    color: $text-muted;
    padding: $line-height-computed $line-height-computed $line-height-computed 0.5rem;

    .audit-icon {
      display: table-cell;
      //vertical-align: middle;
      width: 44px;
    }

    .audit-content {
      display: table-cell;
    }

    .help-block,
    .help-inline {
      font-weight: $font-weight-normal;
    }

    strong {
      color: $text-color;
      font-weight: 600;
    }

    &:first-child {
      border-top: none;
    }
  }

  h4,
  .h4 {
    margin: 0;
  }

  ul {
    margin: $line-height-computed/3 0 0;
    padding-left: 25px;

    &.single-item-list {
      list-style-type: none;
      padding-left: 0;
    }

    li + li {
      margin-top: $line-height-computed/2;
    }
  }
}

.audit-success {
  & > .audit-icon {
    color: $brand-alt;
  }
}

.audit-warning {
  & > .audit-icon {
    color: #ffd03a;
  }
}

.audit-danger {
  & > .audit-icon {
    color: theme-color("danger");
  }
}

.reschedule-modal {
  .row {
    padding-bottom: 10px;
  }
}

.smart-message-preview {
  position: relative;
  padding: 20px;
  background: #eee;
  border-radius: 12px;
  h5 {
    margin-bottom: 0;
    line-height: 1;
  }
  span {
    display: block;
    padding-bottom: 12px;
  }
  .icon-close {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #555;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    opacity: 0.5;
    &:hover {
      text-decoration: none;
    }
  }
}

.smart-message-choices {
  padding: 10px 0;
}

.reply-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.reply-chip {
  padding: 4px 8px;
  margin-right: 12px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 12px;
  color: #7a0ecc;
  border: 1px solid #d5c0ed;
  background: #eae2f8;
  line-height: 1;
}

.reply-content {
  border-radius: 12px;
  background: #f2f2f3;
  margin-top: 15px;
  padding: 12px 20px;
}

.credit-balance {
  line-height: 22px;
}
