// CSS Class Animations

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.activity-dots {
  color: $brand-alt;
  display: inline-block;
  font-family: $font-family-sans-serif;
  font-size: 28px;
  line-height: $font-size-base;
  vertical-align: middle;

  span {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    display: inline-block;

    &:nth-child(2) {
      animation-delay: 0.15s;
    }

    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  }
}

.pulse-opacity {
  animation: pulseOpacity 3s linear infinite;
}

.spin {
  animation: spin 0.8s linear infinite;
}

// Modal

.modal-slide-container {
  @include transition(200ms ease-out height);
  overflow: hidden;
  position: relative;
}

.modal-slide {
  &.slide-left,
  &.slide-right {
    position: absolute;
    bottom: 0;
    top: 0;
    transition: 400ms ease-in-out all;
    width: 100%;
  }

  &.slide-left {
    &.ng-enter {
      transform: translate(100%, 0);
    }
    &.ng-leave,
    &.ng-enter-active {
      transform: translate(0, 0);
    }
    &.ng-leave-active {
      transform: translate(-100%, 0);
    }
  }

  &.slide-right {
    &.ng-enter {
      transform: translate(-100%, 0);
    }
    &.ng-leave,
    &.ng-enter-active {
      transform: translate(0, 0);
    }
    &.ng-leave-active {
      transform: translate(100%, 0);
    }
  }
}

// Inbox Actions
.inbox-actions,
.inbox-nav {
  height: 39px;
  opacity: 1;

  .account-inbox & {
    height: 53px;
    padding-bottom: $line-height-computed/2;
    padding-top: $line-height-computed/2;
  }

  &.ng-hide-add,
  &.ng-hide-remove {
    transition: all 0.2s ease-out;
    overflow: hidden;
  }

  &.ng-hide {
    opacity: 0;
    height: 0;

    .account-inbox & {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

// Angular Animations

.fade-in,
.fade-in-fast {
  opacity: 1;

  &.ng-hide-remove {
    transition: opacity 0.4s ease-out;
  }

  &.ng-hide {
    opacity: 0;
  }

  &.ng-enter {
    transition: opacity 0.4s ease-out;
  }

  &.ng-enter {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

.fade-in-fast {
  &.ng-hide-remove,
  &.ng-enter {
    transition-duration: 200ms;
  }
}

.fade-in-out,
.fade-in-out-fast {
  opacity: 1;

  &.ng-hide-add,
  &.ng-hide-remove,
  &.ng-enter,
  &.ng-leave {
    transition: opacity 0.4s ease-in-out;
  }

  &.ng-hide,
  &.ng-enter,
  &.ng-leave-active {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

.fade-in-out-fast {
  &.ng-hide-add,
  &.ng-hide-remove {
    transition-duration: 200ms;
  }
}

.slide-out-right {
  &.ng-leave {
    transition: all 0.4s ease-out;
  }

  &.ng-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.slide-down,
.slide-down-up {
  opacity: 1;
  transform: translate3d(0, 0, 0);

  &.ng-hide-remove {
    transition: all 0.2s ease-out;
  }

  &.ng-hide {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.slide-down-up {
  &.ng-hide-add {
    transition: all 0.2s ease-out;
  }
}

// Blink
@-moz-keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@-moz-keyframes pulseOpacity {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@-webkit-keyframes pulseOpacity {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes pulseOpacity {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(40deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-30deg);
  }

  70% {
    transform: rotate(20deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.6, 0.6, 0.6) rotate3d(0, 0, 1, -6deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 6deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -6deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

.spin-slow {
  animation: spin 1.3s linear infinite;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(10px, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

// Spin
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Vue Animations

// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 400ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
