form {
  .table {
    margin-bottom: 0;
  }
}

.form-group {
  margin: $grid-gutter-width/2 0;

  &.layout-row {
    > .label {
      margin-right: $grid-gutter-width/2;
    }
  }
}

.form-control {
  //font-size: 13px;

  &:focus {
    //border-color: $blue;
    //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
  }
}

.form-control-loading {
  margin-top: -0.5em;
  position: absolute;
  right: -10px;
  top: 50%;
}

textarea.form-control {
  padding: $line-height-computed/2 $grid-gutter-width/2;
}

fieldset {
  box-shadow: $panel-box-shadow;
  border-radius: $panel-border-radius;
  margin: $panel-body-padding 0 $panel-body-padding * 2;
  padding: $panel-body-padding;

  & + fieldset {
  }

  &.alt {
    background-color: $card-bg;

    h3,
    .h3 {
      //background-color: #fff;
    }
  }

  h3,
  .h3 {
    background-color: $card-cap-bg;
    border-bottom: 1px solid $panel-inner-border;
    font-size: $h5-font-size;
    margin: $panel-body-padding * -1 $panel-body-padding * -1
      $panel-body-padding;
    padding: $panel-heading-padding;

    &.sticky-top {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  h4,
  .h4 {
    &:first-child {
      margin-top: 0;
    }
  }
}

//.input-lg {
//    font-weight: 300;
//    height: auto;
//    letter-spacing: 1px;
//    padding: 6px 12px;
//}

.label {
  @extend label;
}
label,
.label {
  font-weight: 600;

  .checkbox &,
  .radio & {
    font-weight: 400;
    margin: 1px 0;
    padding-left: 20px;

    > input[type="checkbox"],
    > input[type="radio"] {
      margin: 5px 0 0 -20px;
      position: absolute;
    }
  }
}

.label-help {
  color: $text-muted;
  font-size: $font-size-sm;
  font-weight: normal;
}

textarea.autoheight {
  @include transition(100ms linear height);
  overflow: hidden;
}

.form-horizontal .form-group {
  .checkbox,
  .radio {
    padding-top: 0;
  }
}

.selected-field {
  margin-bottom: $line-height-computed/2;
}

.field-selector {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

.field-map-row {
  margin: 0;
  padding: $line-height-computed/2 0;
  position: relative;

  &:hover {
    background-color: $table-hover-bg;
  }

  @include media-breakpoint-down(xs) {
    margin: $line-height-computed 0;

    input.form-control {
      margin-bottom: $line-height-computed;
    }

    .field-options {
      //margin-top: $line-height-computed/2;
    }
  }
}

.field-map-row-remove {
  margin-left: $grid-gutter-width/2;
  position: relative;
  top: 7px;

  a {
    padding: 3px 6px;
  }
}

.field-options {
  //padding-right: 30px;

  md-checkbox,
  md-radio-group md-radio-button {
    margin: 7px $grid-gutter-width 0 0;
  }
}

.form-actions {
  @extend .form-group;
  margin-top: $line-height-computed;

  .btn + .btn {
    margin-left: 5px;
  }

  &.sticky-bottom {
    background: transparentize(#fff, 0.1);
    bottom: 0;
    margin-left: $grid-gutter-width * -1;
    margin-right: $grid-gutter-width * -1;
    padding: $line-height-computed/2 $grid-gutter-width;
    position: sticky;
    z-index: 1;

    md-tab-content & {
      //background: transparentize($panel-default-heading-bg, .1);
      margin-left: 0;
      margin-right: 0;
      padding-left: $panel-body-padding;
      padding-right: $panel-body-padding;
    }
  }
}

.form-text,
.help-block,
.help-inline {
  color: $text-muted;
  //font-weight: $font-weight-light;

  &.small {
    font-weight: $font-weight-normal;
  }

  .emoji {
    margin: -7px 0 -3px;
    height: 1.5em;
    width: auto;
  }
}

.form-text,
.help-block {
  label + & {
    margin-top: -3px;
  }
}

ul.form-text {
  margin-top: 0;
  margin-bottom: 10px;
}

.i-checkbox {
  &:focus {
    outline: none;
  }

  label {
    display: inline;
  }
}

.i-checkbox-check {
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin: -3px 0;
  position: relative;
  width: 16px;

  > .fa {
    color: #fff;
    display: block;
    font-size: 14px;
    position: absolute;
  }

  &.lg {
    height: 20px;
    margin: -4px 0;
    width: 20px;

    > .fa {
      font-size: 18px;
    }
  }

  &.checked {
    background-color: $brand-alt;
    border-color: $brand-alt;

    &.disabled {
      background-color: $gray;
      border-color: $gray;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.35;
  }
}

.i-checkbox-label {
  margin-left: 6px;
}

@include media-breakpoint-up(sm) {
  .form-group.row {
    .col-form-label {
      text-align: right;

      &.text-left {
        text-align: left;
      }
    }

    .timepicker {
      .control-label {
        &.one-line {
          margin-top: 2.1em;
        }
      }
    }
  }
}
